.constellations {
  background-color: #1f0033;

  &.activity-eight-options .activity-word {
    top: 350px;
    left: 306px;
  }

  .container {
    background: url("images/background_sky.svg");
  }

  .intro-part {
    display: block;
    font-size: 22px;
    margin-bottom: 22px;
  }

  .passage-text {
    position: absolute;

    max-width: 343px;
    top: 234px;
    left: 82px;

    font-size: 21px;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    color: #000000;

    opacity: 0;
  }

  .next-button {
    position: absolute;
    top: 85%;
    left: 47%;

    background: #FFEB41;
    font-family: 'Open Sans', sans-serif;
    padding: 10px 25px;
    border-radius: 20px;
    font-size: 24px;
    line-height: 24px;

    &:hover, &:focus {
      background: #7857B0;
      color: #fff
    }

  }

  .dropzone, .box {
    position: absolute;
  }

  .dropzones .dropzone {
    width: 177px;
    height: 177px;
    .drop-active, .drop-inactive {
      margin: 4px;
    }
  }

  .boxes .dropzone, .box {
    width: 177px;
    height: 177px;
  }

  .box {
    top: 0;
    left: 0;
    span.word {
      font-family: 'Open Sans', sans-serif;
      font-size: 22px;
      display: inline-block;
      background-color: #FEFCE3;
      padding: 0 12px 4px 12px;
      border-radius: 16px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .dropzones .dropzone:nth-child(1) {
    top: 276px;
    left: 185px;
    &::after, .drop-animated::after {
      content: '';
      width: 4px;
      height: 100px;
      border-left: 4px dashed #FFF17C;
      position: absolute;
      top: 88px;
      left: 177px;
      transform-origin: 0 0;
      transform: rotate(-89deg);
    }
  }
  .dropzones .dropzone:nth-child(2) {
    top: 90px;
    left: 433px;
    &::after, .drop-animated::after {
      content: '';
      width: 4px;
      height: 80px;
      border-left: 4px dashed #FFF17C;
      position: absolute;
      top: 169px;
      left: 87px;
      transform-origin: 0 0;
    }
  }
  .dropzones .dropzone:nth-child(3) {
    top: 424px;
    left: 610px;
    &::after, .drop-animated::after {
      content: '';
      width: 4px;
      height: 320px;
      border-left: 4px dashed #FFF17C;
      position: absolute;
      top: 81px;
      left: 4px;
      transform-origin: 0 0;
      transform: rotate(110deg);
    }
  }

  .boxes .dropzone:nth-child(1) {
    top: 116px;
    left: 80px;
  }
  .boxes .dropzone:nth-child(2) {
    top: 304px;
    left: 12px;
  }
  .boxes .dropzone:nth-child(3) {
    top: 468px;
    left: 80px;
  }
  .boxes .dropzone:nth-child(4) {
    top: 116px;
    left: 736px;
  }
  .boxes .dropzone:nth-child(5) {
    top: 275px;
    left: 836px;
  }
  .boxes .dropzone:nth-child(6) {
    top: 468px;
    left: 792px;
  }

  .dropzones.fourZones .dropzone:nth-child(1) {
    top: 166px;
    left: 246px;
    &::after, .drop-animated::after {
      content: '';
      width: 4px;
      height: 80px;
      border-left: 4px dashed #FFF17C;
      position: absolute;
      top: 150px;
      left: 102px;
      transform-origin: 0 0;
      transform: rotate(-12deg);
    }
  }
  .dropzones.fourZones .dropzone:nth-child(2) {
    top: 88px;
    left: 468px;
    &::after, .drop-animated::after {
      content: '';
      width: 4px;
      height: 80px;
      border-left: 4px dashed #FFF17C;
      position: absolute;
      top: 169px;
      left: 87px;
      transform-origin: 0 0;
    }
  }
  .dropzones.fourZones .dropzone:nth-child(3) {
    top: 293px;
    left: 466px;
    &::after, .drop-animated::after {
      content: '';
      width: 4px;
      height: 80px;
      border-left: 4px dashed #FFF17C;
      position: absolute;
      top: 118px;
      left: 48px;
      transform-origin: 0 0;
      transform: rotate(69deg);
    }
  }
  .dropzones.fourZones .dropzone:nth-child(4) {
    top: 180px;
    left: 630px;
    &::after, .drop-animated::after {
      content: '';
      width: 4px;
      height: 100px;
      border-left: 4px dashed #FFF17C;
      position: absolute;
      top: 118px;
      left: 48px;
      transform-origin: 0 0;
      transform: rotate(60deg);
    }
  }

  .boxes.eightOptions .dropzone:nth-child(1) {
    top: 108px;
    left: 58px;
  }
  .boxes.eightOptions .dropzone:nth-child(2) {
    top: 271px;
    left: 18px;
  }
  .boxes.eightOptions .dropzone:nth-child(3) {
    top: 434px;
    left: 56px;
  }
  .boxes.eightOptions .dropzone:nth-child(4) {
    top: 490px;
    left: 212px;
  }
  .boxes.eightOptions .dropzone:nth-child(5) {
    top: 108px;
    left: 786px;
  }
  .boxes.eightOptions .dropzone:nth-child(6) {
    top: 271px;
    left: 838px;
  }
  .boxes.eightOptions .dropzone:nth-child(7) {
    top: 434px;
    left: 788px;
  }
  .boxes.eightOptions .dropzone:nth-child(8) {
    top: 470px;
    left: 618px;
  }

  .dropzones .dropzone .drop-animated.correct-option::after {
    border-left: 4px solid #FFF !important;
    z-index: 1;
  }

  .info-text {
    position: absolute;
    top: 20px;
    left: 100px;
    display: inline-block;
    background-color: #fff17c;
    padding: 10px 20px 10px 70px;
    border-radius: 60px;
    font-family: 'Open Sans', sans-serif;
    span {
      font-size: 22px;
      b {
        font-family: 'OpenSans Bold';
      }
    }
    img.info-icon {
      content: url('images/idea-icon.svg');
      position: absolute;
      top: -4px;
      left: -4px;
    }
  }

  .activity-word {
    width: 160px;
    height: 161px;
    background-image: url('images/sun.svg');
    text-align: center;
    font-size: 22px;
    position: absolute;
    top: 280px;
    left: 438px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    z-index: 2;
  }

  .activity-word.fourZones {
    top: 354px;
    left: 308px;
  }

  #constellations-transition {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    pointer-events: none;
  }

  .question .next-button {
    opacity: 1;
    top: 85%;
    left: 50%;
    transform: translateX(-50%);
  }

  .drop-animated {
    width: 177px;
    height: 177px;
  }

  .drop-glow {
    position: absolute;
    width: 250px;
    height: 250px;
    top: -36px;
    left: -36px;
  }

  #payoff-deer, #payoff-eagle {
    position: absolute;
    top: 5px;
    left: 10px;
    display: none;
  }

  #payoff-lion, #payoff-panther {
    position: absolute;
    top: 5px;
    left: -5px;
    display: none;
  }

  #planets {
    position: absolute;
    width: 2099px;
    height: 1422px;
    top: 600px;
    left: -1600px;
    z-index: 4;
    pointer-events: none;
  }

}