.multiple-choice .container {
	max-height: 680px;
	max-width: 1024px;
}

svg {
	pointer-events: none;
}

.keisha {
	background: #593748;

	.full-screen {
	    position: absolute;
	    left: 0;
	    top: 0;
	    height: 100%;
		width: 100%;
	    overflow: hidden;
	}

	.container {
		background-image: url('images/robot-frame.svg');
		background-size: 100% 100%;
		transform-origin: 50% 50%;
		transform: scale(0.5);
	}

	#screenBg {
		background-color: #2e1926;
	}
	
	&-left, &-right {
		position: absolute;
		bottom: 0;
		height: 100%;
	}
	
	&-left {
		left: 0
	}
	
	&-right {
		right: 0
	}

	.passage {
		position: absolute;
		opacity: 0;
		width: 55%;
		height: 55%;
		margin: auto;
		left: 0;
		right: 0;
		top: 24%;
		background: #fff;
		padding: 0;
		border-radius: 12px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	    &-title {
	      background-color: #67429A;
	      color: #ffed5f;
	      padding: 5% 14%;
	      width: 128%;
	      border-radius: 12px 12px 0 0;
	      margin-bottom: 15px;
	      margin-left: -14%;
	      margin-top: -7%;
	      font-family: 'OpenSans Bold';
	      font-size: 27px;
	    }
	    &-container {
	      overflow: hidden;
	      display: flex;
	      flex-direction: column;
	      padding: 5% 10%;
	      border-radius: 12px;
	    }
	    .next-button {
	      position: absolute;
	      top: 102%;
	      left: 50%;
	      transform: translateX(-50%);
	    }
	}

	.question {
	    position: absolute;
	    width: 45%;
	    height: 35%;
	    padding: 3% 6%;
	    border-radius: 12px;
	    top: 50%;
	    left: 50%;
	    transform: translate(-50%, -50%);
	    display: flex;
	    flex-direction: column;
	    justify-content: space-between;
	    color: #000;
	    background-color: #ffed5f;
	    .next-button {
	    	position: absolute;
	    	top: 110%;
	    	left: 50%;
	    	transform: translateX(-50%);
	    }
	}

	.choices {
	    display: flex;
	    justify-content: space-between;
		padding: 44px 30px 30px 30px;
		height: 100%;
		.answers {
			background: #fff;
			border-radius: 12px;
		}
		.prompt {
			padding: 15px 35px;
			background: #ffed5f;
			border-radius: 12px 12px 0 0;
		}
		.article {
			background: #fff;
			margin-top: 45px;
			padding: 60px 22px 22px 40px;
			border-radius: 12px;
		}
	}

	.answer {
	    &-label {
	    	background: #c5c6da;
	    }
    
	    &-button {
			background: #c5c6da;
	    }
	    
	    &-button:hover, &-button.selected {
			background: #67429a;
			color: #fff;
			.answer-label {
				background: #67429a;
				color: #fff;
			}
    	}
	}

	#transition {
		z-index: 1;
		pointer-events: none;
	}

	#haze-transition {
		pointer-events: none;
		z-index: 2;
	}

	.number {
		position: absolute;
		top: 0;
		bottom: 0;
		margin: auto;
		left: 0;
		right: 0;
		opacity: 0;
	}

	.feedback {
	    position: absolute;
	    top: 21%;
		left: 20%;
	    height: 60%;
	    width: 48%;
		padding: 20px 40px;
		border-radius: 14px;
		&-icon {
			margin-bottom: 8px;
		}
		&-next-container {
			position: absolute;
			width: 88%;
			top: 81%;
			text-align: center;
		}
	}

	.next-button {
	    background: #ffed5f;
	    font-family: 'OpenSans';
	    padding: 10px 25px;
	    border-radius: 20px;
	    font-size: 24px;
	    line-height: 24px;
	    &:hover, &:focus {
			background: #976396;
			color: #fff
	    }
	}

	.feedback-card.correct #keisha-incorrect {
		display: none;
	}

	.feedback-card.incorrect #keisha-correct {
		display: none;
	}

	#keisha-gear, #keisha-gear1, #keisha-gear2 {
		transform-origin: 50% 50%;
		z-index: 1;
		position: absolute;
		width: 106px;
		height: 107px;
	}

	#keisha-gear {
		top: 20%;
		left: 17%;
		opacity: 0;
	}

	#keisha-gear-small {
		position: absolute;
		z-index: 1;
		width: 80px;
		height: 78px;
	}

	&.multiple-choice {
		.passage-container, .article > span, ul.answers-container {
			&::-webkit-scrollbar {
				&-thumb {
				  background-color: rgba(103, 66, 154, 1);
				}
				&-button {
					&:vertical:decrement { /* Up */
						background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" preserveAspectRatio="none"><path d="M45.66,43.5a3,3,0,0,1-2.6,4.5H4.94a3,3,0,0,1-2.6-4.5l19.06-33a3,3,0,0,1,5.2,0Z" fill="rgba(103, 66, 154, 1)" /></svg>');
					}
					&:vertical:increment { /* Down */
						background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" preserveAspectRatio="none"><path d="M45.66,4.5A3,3,0,0,0,43.07,0H4.94a3,3,0,0,0-2.6,4.5l19.06,33a3,3,0,0,0,5.2,0Z" fill="rgba(103, 66, 154, 1)" /></svg>');
					}
				}
			}
		}
		.question-text {
			&::-webkit-scrollbar {
				&-track {
		    		background-color: rgba(255, 255, 255, 1);
		    	}
				&-thumb {
					background-color: rgba(103, 66, 154, 1);
				}
				&-button {
					background-color: rgba(255, 255, 255, 1);
					&:vertical:decrement { /* Up */
						background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" preserveAspectRatio="none"><path d="M45.66,43.5a3,3,0,0,1-2.6,4.5H4.94a3,3,0,0,1-2.6-4.5l19.06-33a3,3,0,0,1,5.2,0Z" fill="rgba(103, 66, 154, 1)" /></svg>');
					}
					&:vertical:increment { /* Down */
						background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" preserveAspectRatio="none"><path d="M45.66,4.5A3,3,0,0,0,43.07,0H4.94a3,3,0,0,0-2.6,4.5l19.06,33a3,3,0,0,0,5.2,0Z" fill="rgba(103, 66, 154, 1)" /></svg>');
					}
				}
			}
		}
	}

  .passage-text {
    overflow-y: auto;
    display: block;
    margin-bottom: 0;
    &::-webkit-scrollbar {
      width: 48px;
      &-track {
        background-color: rgba(233, 233, 233, 1);
        border-left: 8px solid rgba(128, 128, 128, 0);
        border-right: 8px solid rgba(128, 128, 128, 0);
        background-clip: padding-box;
      }
      &-thumb {
        background-color: rgba(103, 66, 154, 1);
        border: 13px solid rgba(128, 128, 128, 0);
        border-radius: 32px;
        background-clip: padding-box;
      }
      &-corner {
        display: none;
      }
      &-button {
        background-size: 80% 90%;
        background-repeat: no-repeat;
        height: 32px;
        width: 48px;
        background-color: rgba(233, 233, 233, 1);
        background-clip: padding-box;
        border-left: 8px solid rgba(128, 128, 128, 0);
        border-right: 8px solid rgba(128, 128, 128, 0);
        &:start {
          display: block;
        }
        &:end {
          display: block;
        }
        &:vertical:decrement { /* Up */
          background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" preserveAspectRatio="none"><path d="M45.66,43.5a3,3,0,0,1-2.6,4.5H4.94a3,3,0,0,1-2.6-4.5l19.06-33a3,3,0,0,1,5.2,0Z" fill="rgba(103, 66, 154, 1)" /></svg>');
          border-top: 8px solid rgba(128, 128, 128, 0);
          background-position: center bottom;
          &:start {
            border-top-left-radius: 32px;
            border-top-right-radius: 32px;
          }
          &:end {
            display: none;
          }
        }
        &:vertical:increment { /* Down */
          background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" preserveAspectRatio="none"><path d="M45.66,4.5A3,3,0,0,0,43.07,0H4.94a3,3,0,0,0-2.6,4.5l19.06,33a3,3,0,0,0,5.2,0Z" fill="rgba(103, 66, 154, 1)" /></svg>');
          border-bottom: 8px solid rgba(128, 128, 128, 0);
          background-position: center top;
          &:start {
            display: none;
          }
          &:end {
            border-bottom-left-radius: 32px;
            border-bottom-right-radius: 32px;
          }
        }
      }
    }
  }

	&::-webkit-scrollbar {
		&-thumb {
		  background-color: rgba(103, 66, 154, 1);
		}
		&-button {
			&:vertical:decrement { /* Up */
				background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" preserveAspectRatio="none"><path d="M45.66,43.5a3,3,0,0,1-2.6,4.5H4.94a3,3,0,0,1-2.6-4.5l19.06-33a3,3,0,0,1,5.2,0Z" fill="rgba(103, 66, 154, 1)" /></svg>');
			}
			&:vertical:increment { /* Down */
				background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" preserveAspectRatio="none"><path d="M45.66,4.5A3,3,0,0,0,43.07,0H4.94a3,3,0,0,0-2.6,4.5l19.06,33a3,3,0,0,0,5.2,0Z" fill="rgba(103, 66, 154, 1)" /></svg>');
			}
		}
	}

}