.chart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding-top: 50px;

  .y {
    .tick {
      font-size: 24px;
      font-family: OpenSans Bold, serif;
      color: #14405c;
    }
  }

  .x {
    .tick {
      font-size: 21px;
      font-family: OpenSans, serif;
      color: #14405c;
    }
  }

  g {
    pointer-events: all;
  }
}

div.bar-tooltip {
  text-align: center;
  min-width: 162px;
  padding: 10px 20px;
  background-color: #14405c;
  border: 2px solid white;
  border-radius: 10px;
  opacity: 0;
  font-family: OpenSans, sans-serif;
  font-size: 21px;

  &::after {
    border-top-color: #14405c !important;
  }

  &.show {
    opacity: 1;
  }

  p:not(:last-child) {
    margin-bottom: 5px;
  }
}

.chart-legend {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  align-self: flex-end;
  max-width: 90%;
  width: 100%;
  margin-top: 30px;

  &-item {
    position: relative;
    padding-left: 25px;
    font-size: 21px;
    font-family: OpenSans, sans-serif;
    color: #14405c;

    &::before {
      content: '';
      position: absolute;
      display: block;
      top: 50%;
      left: 0;
      box-sizing: border-box;
      width: 16px;
      height: 16px;
      border: 2px solid #14405c;
      background: #fff;
      transform: translate(0, -50%);
    }

    &-total::before {
      background: #14405c;
    }
  }
}
