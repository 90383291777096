.constellations-intro {
  display: flex;
  flex-direction: column;

  &-moon {
    position: absolute;
    top: 100%
  }

  &-bulb {
    position: absolute;
    z-index: 4;
    top: 19%;
    left: 22%;

    opacity: 0;
  }

  &-star {
    opacity: 0;
    z-index: 10;

    &-1 {
      position: absolute;
      left: 50%;
      top: 15%;
    }

    &-2 {
      position: absolute;
      left: 65%;
      top: 21%;
    }

    &-3 {
      position: absolute;
      left: 80%;
      top: 15%;
    }

    p {
      font-size: 21px;
      font-family: OpenSans, sans-serif;
      color: #000;

      text-align: center;
      margin-top: -100px;
    }
  }

  &-example {
    position: absolute;
    top: 0;
    left: 0;
  }

  &-title {
    display: flex;
    justify-content: center;

    padding: 20px 0;

    font-size: 64px;
    font-family: OpenSans, sans-serif;
    color: #FFF689;
  }

  &-subtitle {
    display: flex;
    justify-content: center;

    font-size: 36px;
    font-family: OpenSans, sans-serif;
    color: #FEFCE3;
  }

  &-images {
    padding: 10px 30px;

    &-elephant {
      position: absolute;
      top: 120px;
      left: 20px;
    }

    &-swan {
      position: absolute;
      top: 320px;
      left: 300px;
    }

    &-ram {
      position: absolute;
      top: 120px;
      right: 20px;
    }
  }
  #constellations-directions {
    opacity: 0;
    display: none;
  }
}
