.mastered-words-modal-content {
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  height: 100%;

  .mastered-words-arrow {
    position: fixed;
    top: 50%;

    &-left {
      left: -20px;
    }

    &-right {
      right: -20px;
    }
  }

  .mastered-words-modal {
    display: flex;
    flex-direction: row;

    background-color: white;

    width: calc(100% - 40px);
    height: calc(100% - 20px);
    margin: 10px 20px;
    border-radius: 30px;

    &-column {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      .mastered-word {
        padding: 20px;

        font-family: OpenSans, sans-serif;
        font-size: 24px;
        font-weight: bold;
        color: #000;

        &:first-child {
          padding-top: 55px;
        }
      }
    }

    &-divider {
      width: 2px;
      background: #979797;
    }
  }

  .close-button {
    position: fixed;
    top: 20px;
    right: 30px;

    width: 50px;
    height: 50px;

    background-color: #14405c;
    color: white;

    border-radius: 25px;

    font-family: OpenSans, serif;
    font-size: 29px;
    font-weight: bold;

    &:hover {
      font-size: 32px;
    }
  }

  .mastered-words-ribbon {
    z-index: 2;
    position: absolute;
    left: 41.5%;
    top: -12%;
  }
}

.fade-in {
  -webkit-animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
