.home-page-footer {
  font-size: 1.5em;
  font-family: OpenSans, serif;
  color: #fff;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .connection-block {
    height: 100%;
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    align-items: center;

    .sound-icon {
      padding: 0 20px;
    }
  }

  .footer-stats {
    flex-grow: 6;
    height: 100%;
    justify-content: space-around;
    display: flex;
    flex-direction: row;
    align-items: center;

    .footer-stats-item {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0 10px;

      div {
        padding: 10px;
      }

      .big-font {
        font-size: 2em;
        font-weight: 600;
      }
    }

    .footer-level {
      &-title {
        line-height: 1em;
        margin-bottom: 10px;
      }
    }
  }
}
