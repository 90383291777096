@import "src/app/styles/variables.module";

.splitDropdownButton {
    display: flex;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: 300px;

    .leftSplit {
        height: 100%;
        width: 30%;
        border-right: 2px solid black;
        text-align:left;
        
        .selectName {
            line-height: 32px;
            font-family: OpenSans;
            font-size: 21px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            color: $theme-reporting-color;
          
        
            overflow: hidden;
            white-space:nowrap;
            text-overflow:ellipsis;
            vertical-align: middle;
        }
    }

    .rightSplit {
        height: 100%;
        width: 100%;
        display: flex;
        
        .selectedStream {
            text-align: left;
            width: 90%;
            line-height: 30px;
            
            .selectedIcon {
                height: 50%;
                vertical-align: middle;
                padding-left: 10px;
            }

            .selectedText {
                padding-left: 10px;
                vertical-align: middle;
                font-size: 19px;
                font-weight: 300;
                color: $theme-reporting-color;
                font-family: 'OpenSans Bold';
                list-style: none;
            
                overflow: hidden;
                white-space:nowrap;
                text-overflow:ellipsis;
                vertical-align: middle;
            }
        }

        .carret {
            padding-right: 5px;
            padding-top: 5px;
            float: right;
            height: 100%;
            vertical-align: middle;
        }  


        .split-dropdown-menu {
            display: none;
        }
        .split-dropdown-menu.open {
            display: block;
            position:fixed;
            background-color: white;
            
            margin-top: 34px;
            
            width: 295px;
            max-height: 250px;

            overflow-y: scroll;
            overflow-x: hidden;
            text-overflow: ellipsis;

            border: 2px solid $theme-reporting-color;
        
            :hover {
                background-color: lightgrey;
            }
        
            .spanMenuOption {
                height: 34px;
                white-space: nowrap;
                line-height: 30px;
                color: $theme-reporting-color;
                display: block;
                width: 100%;

                text-align: left;
                padding-left: 10px;

                border-bottom: 1px solid black;

                .menuOptionIcon {
                    padding-right: 10px;
                    height: 50%;
                    height: 50%;
                    vertical-align: middle;
                }

                .menuOptionText {
                    vertical-align: middle;
                }
            }

            a:last-child {
                border-bottom: 0px;
            }
        }
    }
}

.split-dropdown-menu::-webkit-scrollbar {
    width: 8px;
}

.split-dropdown-menu::-webkit-scrollbar-thumb {
    border-radius: 0px;
    border: 6px solid $theme-reporting-color;
    background-color: white;
    background-clip: content-box;
}
