@import "src/app/styles/variables.module";

$num_cols: 8;
$scroll-width: 2ch;

.reportingPage {
    .centeredPage {
        .wordsPage {
            #nonCoreTable {
                height: 100%;

                thead {
                    height: 100%;

                    tr {
                        height: 100%;

                        &.streamAbove {
                            height: 100%;

                            th {
                                padding-bottom: 5px;
                            }

                            border-bottom: 2px solid $theme-reporting-color;
                        }

                        &.headerRow {
                            height: 100%;

                            th {
                                padding-top: 5px;
                                padding-bottom: 5px;
                            }
                        }

                        .noGradeRow,
                        .academic,
                        .gradeRow,
                        .striving {
                            border-left: 2px solid $theme-reporting-color;
                        }

                        .academic {
                            padding-left: 10px;
                        }

                    }
                }

                tbody {
                    tr {
                        &:hover {
                            color: white;
                        }
                    }

                    td {
                        height: 100%;
                        border-left: 2px solid $theme-reporting-color;
                    }

                    .name,
                    .partOfSpeech {
                        padding-left: 5px;
                    }
                }

                tbody td,
                thead th {
                    // divided by num cols
                    width: calc(100% / $num_cols);
                    float: left;
                }

                th {
                    height: 100%;

                    &.wordRow {
                        width: calc((100% / $num_cols) * 3);
                    }

                    &.noGradeRow {
                        width: calc((100% / $num_cols) * 2);
                    }

                    &.gradeRow {
                        width: calc((100% / $num_cols) * 3);
                    }
                }

            }
        }
    }
}