.footer {
  display: flex;

  .vcentered {
    display: flex;
    text-align: center;
    justify-content: center;
    flex-direction: column;
  }

  .hcentered {
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .emptySection {
    flex-grow: 100;
  }

  .sound-icon {
    margin-left: 20px;
  }

  .internet-icon {
    margin-left: 10px;
  }

  .rightSection {
    margin-right: 10px;
    .gear-export {
      flex-grow: 3;
      height: 30px;
      margin-top: 20px;

      .gear-icon {
        margin-top: 30px;
        height: 40px;
        width: 40px;
      }
    }
  }


}
