@import "src/app/styles/variables.module";

$scroll-width: 2ch;
$thumb-border-width: 6px;

.wordModal {
    .background-opacity{
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, .5);
        opacity: 0;
        transition: opacity .2s ease;
        display: flex;
        opacity: 1;
        z-index: 9;
    }

    .closeBtn {
        position: absolute;
        top: 14px;
        right: 19px;
        width: 36px;
        height: 36px;
        background: url("../../shared/images/teacher/x-button.svg") no-repeat;
        background-position: center;
        cursor: pointer;
    }
    
    #word-popup-modal {
        opacity: 100%;
        overflow: scroll;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
    
        max-width: 75vw;
        width: 1500px;
    
        max-height: 75vh;
        height: 100%;
    
        box-sizing: border-box;
        padding: 20px 20px 35px 35px;
        background-color: white;
        border-radius: 20px;
        border: 5px solid $theme-reporting-color;
        color: black;
        font-size: 16px;
        z-index: 10;
        
        section.wordDetails {
            width: 40%;
            height: 100%;
            color: #000;
            float: left;
            padding: 30px 0px 30px 0px;
    
            .wordName {
                font-size: 36px;
                color: #285573;
                margin-bottom: 25px;
                line-height: 34px;
            }
    
            .infoLabel {
                width: 85%;
                font-family: 'OpenSans Bold';
                color: #b2b2b2;
                margin-top: 15px;
                margin-bottom: 10px;
            }

            .infoValue  {
                width: 85%;
                p {
                    margin: 3px 8px 19px 0;
                    font-size: 20px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: $theme-reporting-color;
                }

                &span {
                    cursor: pointer;
                    color: #285573;
                }
            }
        }

        section.wordActivities {
            width: 40% ;
            height: 100%;
            float: right;

            .activities {
                width: 100%;
                text-align: center;
                overflow-y: scroll;
                // Height is - launch button - top text - top margin
                height: calc(100% - 30px - 18px - 40px);

                &::-webkit-scrollbar {
                    width: $scroll-width;
                }
                
                &::-webkit-scrollbar-track {
                    background-color: $theme-reporting-color;
                    border-radius: 0px;
                }
                
                &::-webkit-scrollbar-thumb {
                    border-radius: 0px;
                    border: $thumb-border-width solid $theme-reporting-color;
                    background-color: white;
                    background-clip: content-box;
                }
    
                &::-webkit-scrollbar-thumb:hover {
                    background-color: rgb(234, 233, 233);
                }
            }
    
            .activityListLabel {
                color: #f7956e;
                font-family: 'OpenSans Bold';
                margin-top: 40px;
                width: 100%;
                text-align: center;
            }
            
            .activityList {
                text-overflow: ellipsis;
                margin: 0 24px 0 calc($scroll-width + $thumb-border-width);
    
                .activityItem {
                    width: 100%;
                    max-width: 300px;

                    height: 100%;
                    min-height: 60px;

                    border: 2px solid #879dac;
                    background-color: #fff;
                    border-radius: 3px;
                    margin: 5px 0;
                    margin-left: calc($scroll-width);
                    line-height: 20px;
                    cursor: pointer;
    
                    .activityListLabel {
                        width: 100%;
                        color: #000;
                        margin-top: 7px;
                        padding: 0 5px 0 10px;
                        text-align: left;
    
                        span {
                            &.activityType {
                                color: black;
                            }
                            &.activityDescription {
                                color: #818181;
                            }
                        }
                    }
    
                    .standard {
                        display: none;
                        color: #000;
                    }
                }
    
                .activityItem.selected {
                    background-color: #879dac;
    
                    .standard, .activityListLabel {
    
                        span {
                            &.activityDescription {
                                color: #ddd;
                            }
                            &.activityType {
                                color: #fff;
                            }
                        }
                    }
                }
            }
    
            #launchButton {
                border: 2px solid #f7956e;
                color: #f7956e;
                font-weight: bold;
                width: 296px;
                padding: 6px 0 4px 0;
                margin-top: 20px;
                margin:0 auto;

                display: flex;
                justify-content: center;
            }
        }
    }    
}