

.tab-pane {
    .centeredConfirmation {
        width: 100%;
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;

        .statusIcon {
            width: 80px;
            height: 80px;
        }
    }
}
