.notebooks {
  background-color: #000;

	.activity-card {
		opacity: 0;
		transform: scale(0);
	}

	.container {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		background: url("images/sea-background.svg");
		background-size: 100% 100%;
	}

	.title-screen {
		position: absolute;
		z-index: 1;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		overflow: hidden;
		pointer-events: none;
		background-color: #000;
	}

	.notebook-bg, .notebook-bg-feedback {
	    width: calc(100% - 160px);
	    position: absolute;
	    /*top: 50%;
	    left: 50%;
	    transform: translate(-50%, -50%);*/
	    transform-origin: 50% 50%;
	    top: 0;
	    bottom: 0;
	    left: 0;
	    right: 0;
	    margin: auto;
	}

	.notebook-bg {
		opacity: 0;
	}

	.notebook-swipe {
	    position: absolute;
    	top: 0;
	    right: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}

	.passage {
	    column-count: 2;
	    width: calc(100% - 160px);
	    height: 100%;
	    margin: auto;
	    padding: 90px 100px 110px 100px;
	    column-gap: 60px;
	    font-family: 'OpenSans';
	    font-style: normal;
	    font-weight: 400;
	    font-size: 22px;
	    line-height: 37px;
	    .next-button {
	    	margin-top: 300px;
	    }
	}

	.question {
		background-color: #F2DFBE;
	    width: 70%;
	    min-height: 215px;
	    height: fit-content;
	    margin: auto;
	    padding: 40px 45px 25px 40px;
	    border-radius: 15px;
	    position: absolute;
	    left: 0;
	    right: 0;
	    top: 0;
	    bottom: 0;
	    &-text {
	    	max-height: 500px;
	    }
	}

	.choices {
		display: flex;
		justify-content: space-between;
		padding: 44px 0 20px 0;
		height: 100%;
		.prompt {
			padding: 15px 35px;
		}
	    .article {
			width: calc(46% - 8px);
			padding: 137px 22px 0 40px;
		}
		.answers {
			width: calc(50% - 8px);
			padding-top: 30px;
		}
		.notebook-bubbles {
			position: absolute;
			left: 0;
			bottom: 11px;
			z-index: 1;
		}
		.notebook-bg-big {
		    position: absolute;
		    left: 50%;
		    transform-origin: 50% 50%;
	    	top: 4%;
	    	transform: translate(-50%, 0);
			width: calc(100% + 30px);
		}
	}

	.article {
		z-index: 1;
	}

	.answers-container {
	    padding: 15px 10px 0 60px;
	    margin-right: 20px;
	}

	.answer, .answer-item  {
	    &-label {
			background: #fff;
			border-color:#DCC5AD;
	    }
	    
	    &-button {
			background: #fff;
	    }
	    
	    &-button:hover, &-button.selected  {
			background: #804A00;
			color: #fff;
			.answer-label {
		        background: #804A00;
		        color: #fff;
		    }
	    }
	}

	.feedback-animation {
		position: absolute;
	    z-index: 2;
	    left: 0;
	    top: 0;
	    height: 100%;
		width: 100%;
	    overflow: hidden;
	    pointer-events: none;
	}

	.feedback-card {
		opacity: 0;
		transform: scale(0);
		width: 100%;
		height: 100%;
	}

	.feedback {
		background: none;
		position: absolute;
		width: 100%;
		height: 100%;
		padding: 160px 170px 190px 180px;
	    margin: auto;
		&-icon, &-title {
			background: #FFDD71;
			width: 280px;
			margin-left: 10px;
			text-align: center;
			position: absolute;
		}
		&.correct .feedback-icon, 
		&.correct .feedback-title {
			background: #77FF73;
		}
		&-icon {
			border-top-left-radius: 15px;
			border-top-right-radius: 15px;
			padding-bottom: 35px;
			padding-top: 35px;
			position: absolute;
			margin-top: 10px;
		}
		&-title {
			border-bottom-left-radius: 15px;
			border-bottom-right-radius: 15px;
			padding: 5px 20px 35px 20px;
			float: left;
			margin-top: 160px;
		}
		&-next-container {
			width: 50%;
			position: absolute;
			right: 80px;
			bottom: 20%;
			text-align: center;
		}
		&-text {
			float: right;
			width: 42%;
			height: 100%;
			overflow-y: auto;
			overflow-x: hidden;
		}
		&-container {
			display: block;
			overflow-y: hidden;
			width: 100%;
			height: 100%;
		}
	}

	.next-button {
		background: #00438F;
	    font-family: "OpenSans";
		color: #fff;
	    padding: 10px 25px;
	    border-radius: 20px;
	    font-size: 24px;
	    line-height: 24px;
	    margin-bottom: 0;
	    &:hover, &:focus {
		    background: #0094D9;
		    color: #000
	    }
	}

	&.multiple-choice {
	    .passage-container, .question-text, .article > span, ul.answers-container {
	      &::-webkit-scrollbar {
	        &-thumb {
	          background-color: rgba(116, 75, 41, 1);
	        }
	        &-button {
	        	background-color: rgba(255, 255, 255, 1);
	          &:vertical:decrement { /* Up */
	            background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" preserveAspectRatio="none"><path d="M45.66,43.5a3,3,0,0,1-2.6,4.5H4.94a3,3,0,0,1-2.6-4.5l19.06-33a3,3,0,0,1,5.2,0Z" fill="rgba(116, 75, 41, 1)" /></svg>');
	          }
	          &:vertical:increment { /* Down */
	            background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" preserveAspectRatio="none"><path d="M45.66,4.5A3,3,0,0,0,43.07,0H4.94a3,3,0,0,0-2.6,4.5l19.06,33a3,3,0,0,0,5.2,0Z" fill="rgba(116, 75, 41, 1)" /></svg>');
	          }
	        }
	        &-track {
				background-color: rgba(255, 255, 255, 1);
			}
	      }
	    }
	}

	.feedback-text {
	    overflow-y: auto;
	    display: block;
	    margin-bottom: 0;
	    &::-webkit-scrollbar {
	      width: 48px;
	      &-track {
	        background-color: rgba(255, 255, 255, 1);
	        border-left: 8px solid rgba(128, 128, 128, 0);
	        border-right: 8px solid rgba(128, 128, 128, 0);
	        background-clip: padding-box;
	      }
	      &-thumb {
	        background-color: rgba(116, 75, 41, 1);
	        border: 13px solid rgba(128, 128, 128, 0);
	        border-radius: 32px;
	        background-clip: padding-box;
	      }
	      &-corner {
	        display: none;
	      }
	      &-button {
	        background-size: 80% 90%;
	        background-repeat: no-repeat;
	        height: 32px;
	        width: 48px;
	        background-color: rgba(255, 255, 255, 1);
	        background-clip: padding-box;
	        border-left: 8px solid rgba(128, 128, 128, 0);
	        border-right: 8px solid rgba(128, 128, 128, 0);
	        &:start {
	          display: block;
	        }
	        &:end {
	          display: block;
	        }
	        &:vertical:decrement { /* Up */
	          background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" preserveAspectRatio="none"><path d="M45.66,43.5a3,3,0,0,1-2.6,4.5H4.94a3,3,0,0,1-2.6-4.5l19.06-33a3,3,0,0,1,5.2,0Z" fill="rgba(116, 75, 41, 1)" /></svg>');
	          border-top: 8px solid rgba(128, 128, 128, 0);
	          background-position: center bottom;
	          &:start {
	            border-top-left-radius: 32px;
	            border-top-right-radius: 32px;
	          }
	          &:end {
	            display: none;
	          }
	        }
	        &:vertical:increment { /* Down */
	          background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" preserveAspectRatio="none"><path d="M45.66,4.5A3,3,0,0,0,43.07,0H4.94a3,3,0,0,0-2.6,4.5l19.06,33a3,3,0,0,0,5.2,0Z" fill="rgba(116, 75, 41, 1)" /></svg>');
	          border-bottom: 8px solid rgba(128, 128, 128, 0);
	          background-position: center top;
	          &:start {
	            display: none;
	          }
	          &:end {
	            border-bottom-left-radius: 32px;
	            border-bottom-right-radius: 32px;
	          }
	        }
	      }
	    }
	}

    &::-webkit-scrollbar {
        &-thumb {
          background-color: rgba(116, 75, 41, 1);
        }
        &-button {
        	background-color: rgba(255, 255, 255, 1);
          &:vertical:decrement { /* Up */
            background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" preserveAspectRatio="none"><path d="M45.66,43.5a3,3,0,0,1-2.6,4.5H4.94a3,3,0,0,1-2.6-4.5l19.06-33a3,3,0,0,1,5.2,0Z" fill="rgba(116, 75, 41, 1)" /></svg>');
          }
          &:vertical:increment { /* Down */
            background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" preserveAspectRatio="none"><path d="M45.66,4.5A3,3,0,0,0,43.07,0H4.94a3,3,0,0,0-2.6,4.5l19.06,33a3,3,0,0,0,5.2,0Z" fill="rgba(116, 75, 41, 1)" /></svg>');
          }
        }
        &-track {
			background-color: rgba(255, 255, 255, 1);
		}
    }

}