.loadingActivity {
    display: block;
    position: relative;
    margin: auto;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 10;
    text-align: center;

    img {
        width: 150px;
        height: 150px;
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      
    }
}
