.askquery {
    background-color: black;

    .container {
        background-image: url('images/background-main.svg')
    }

    .button {
        background-color: #64EDFF;
        border-radius: 20px;

        .answer-label {
            background-color: #64EDFF;
        }

        &:hover, &.selected {
            background-color: #5770AF;
            color: white;
            .answer-label {
                background-color: #5770AF;
                color: white;
            }
        }
    }

    .next-button {
        position: absolute;
        background-color: #64EDFF;
        top: 700px;
        left: 50%;
        transform: translateX(-50%);
        pointer-events: auto;
    }

    /*Intro*/

    #queryTitle,
    #queryTitle2 {
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
    }

    #queryTitle2,
    #backgroundMain,
    #balloonQuerySmall,
    #balloonCatSmall,
    #cat_head,
    #queryLeftUp,
    #balloonCatLarge,
    #catHead,
    #catHeadLarge,
    #queryChatLargeWrapper,
    #catHeadQuestion,
    #queryStraightQuestion,
    #boxQuestion,
    #leftDropdown,
    #queryFeedbackIcon {
        opacity: 0;
        position: absolute;
    }

    background-color: #000;

    #next-button-to-passage {
        position: absolute;
        font-family: "OpenSans", sans-serif;
        padding: 10px 25px;
        border-radius: 20px;
        font-size: 24px;
        line-height: 24px;
        top: 700px;
        left: 50%;
        transform: translateX(-50%);
    }

    #balloonQuerySmall,
    #balloonCatSmall {
        z-index: 10;
    }

    #queryStraight {
        top: 180px;
        left: 1200px;
        position: absolute;
    }

    #catHead {
        top: 80px;
    }

    #queryLeftUp {
        top: 380px;
        left: 1200px;
    }

    #catHeadLarge {
        top: 420px;
    }

    #balloonCatSmall {
        top: 80px;
        left: 170px;
    }

    #balloonCatLarge {
        left: 170px;
        top: 380px;
    }

    #balloonQuerySmall {
        top: 380px;
        left: 320px;
    }

    #catChatSmallText {
        position: relative;
        top: 140px;
        left: 225px;
        color: black;
        opacity: 0;
        z-index: 20;
    }

    #queryChatSmallText {
        position: absolute;
        top: 400px;
        left: 350px;
        color: black;
        opacity: 0;
        z-index: 20;
        height: 114px;
        width: 376px;
        overflow-y: auto;
        display: flex;
        margin: 0;
        span {
            margin: auto;
        }
    }

    p,
    h1,
    .text {
        color: #000;
        font-family: 'OpenSans';
        font-style: normal;
        font-weight: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 33px;
    }

    #feedbackTransition {
        display: none;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;
    }

    #feedbackScreen {
        opacity: 0;
        transform: scale(0);
        z-index: 2;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;        
        transform-origin: 50% 50%;
        pointer-events: none;
    }

    .question-text {
        position: absolute;
        width:  560px;
        height: 222px;
        top: 214px;
        left: 232px;
        display: flex;
        span {
            margin: auto;
        }
    }

    /*Choices*/

    .choices {
        width: 100%;
        height: 100%;
        #choices-screen {
            width: 100%;
            height: 100%;
        }

        #leftDropdown {
            opacity: 0; display: 'none'; position: 'absolute';
            top: -1000px;
            left: 20px;
        }
        #rightDropdown {
            opacity: 0; display: 'none'; position: 'absolute';
            top: 1000px;
            left: 470px;
        }
    }

    .activity-card {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        .article {
            position: absolute;
            top: 200px;
            left: 75px;
            width: 330px;
            height: 450px;
        }
    }

    .answers {
        left: 470px;
        top: 40px;
        position: absolute;
        height: calc(100% - 60px);
        width: 514px;
        background-color: #fff;
        padding: 0;
        clip-path: polygon(20px 0, calc(100% - 20px) 0, calc(100% - 20px) 20px, 100% 20px, 100% calc(100% - 20px), calc(100% - 20px) calc(100% - 20px), calc(100% - 20px) 100%, 20px 100%, 20px calc(100% - 20px), 0 calc(100% - 20px), 0 20px, 20px 20px);
        .prompt {
            padding: 22px 30px;
            background-color: #64EDFF;
            width: 100%;
        }
        .answers-container {
            padding: 30px 20px 30px 40px;
            margin-right: 20px;
        }
    }

    /*Feedback*/

    #catHeadFeedback {
        position: absolute;
        left: 150px;
        top: 90px;
    }

    .feedback-card {
        display: inherit;
        position: absolute;
        top: 0;
        left: 0;

        #queryFeedbackIcon {
            position: absolute;
            left: 455px;
            top: 80px;
        }

        .feedback {
            position: absolute;
            width: 800px;
            left: 112px;
            top: 200px;
            opacity: 0;
            display: flex;
            flex-direction: row;

            &.correct {
                background: url(images/box_correct.svg) no-repeat;
                width: 790px;
                height: 330px;
            }

            &.incorrect {
                background: url(images/box_incorrect.svg) no-repeat;
                width: 790px;
                height: 330px;
            }

            .feedback-icon {
                float: left;
                margin-top: 80px;
                margin-left: 50px;
                width: 10%;
            }

            .feedback-container {
                width: 80%;
                margin: 20px 0;
            }

            .feedback-title {
                font-weight: bold;
                font-size: 24px;
                margin-left: 20px;
                margin-top: 50px;
            }

            .feedback-text {
                margin-left: 20px;
                padding-bottom: 50px;
                width: 500px;
            }
        }
    }

    .passage {

        pointer-events: none;

        .passage-title, .passage-text {
            position: absolute;
            font-weight: normal;
            font-size: 22px;
            line-height: 33px;
            /*display: none;*/
            opacity: 0;
            display: flex;
            overflow-y: auto;
            pointer-events: auto;
            span {
                margin: auto;
            }
        }
        
        .passage-text {
            top: 222px;
            left: 140px;
            width: 585px;
            height: 316px;
            display: none;
        }
        .passage-title {
            top: 400px;
            left: 220px;
            width: 630px;
            height: 114px;
        }

    }

    &.multiple-choice {
        ul.answers-container {
            &::-webkit-scrollbar {
                &-thumb {
                  background-color: rgba(103, 66, 154, 1);
                }
                &-button {
                    &:vertical:decrement { /* Up */
                        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" preserveAspectRatio="none"><path d="M45.66,43.5a3,3,0,0,1-2.6,4.5H4.94a3,3,0,0,1-2.6-4.5l19.06-33a3,3,0,0,1,5.2,0Z" fill="rgba(103, 66, 154, 1)" /></svg>');
                    }
                    &:vertical:increment { /* Down */
                        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" preserveAspectRatio="none"><path d="M45.66,4.5A3,3,0,0,0,43.07,0H4.94a3,3,0,0,0-2.6,4.5l19.06,33a3,3,0,0,0,5.2,0Z" fill="rgba(103, 66, 154, 1)" /></svg>');
                    }
                }
            }
        }
        .passage-container, .article > span, .question-text {
            &::-webkit-scrollbar {
                &-track {
                    background-color: rgba(255, 255, 255, 1);
                }
                &-thumb {
                  background-color: rgba(103, 66, 154, 1);
                }
                &-button {
                    background-color: rgba(255, 255, 255, 1);
                    &:vertical:decrement { /* Up */
                        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" preserveAspectRatio="none"><path d="M45.66,43.5a3,3,0,0,1-2.6,4.5H4.94a3,3,0,0,1-2.6-4.5l19.06-33a3,3,0,0,1,5.2,0Z" fill="rgba(103, 66, 154, 1)" /></svg>');
                    }
                    &:vertical:increment { /* Down */
                        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" preserveAspectRatio="none"><path d="M45.66,4.5A3,3,0,0,0,43.07,0H4.94a3,3,0,0,0-2.6,4.5l19.06,33a3,3,0,0,0,5.2,0Z" fill="rgba(103, 66, 154, 1)" /></svg>');
                    }
                }
            }
        }
    }

  .passage-title, #queryChatSmallText {
    &::-webkit-scrollbar {
      width: 48px;
      &-track {
        background-color: rgba(233, 233, 233, 1);
        border-left: 8px solid rgba(128, 128, 128, 0);
        border-right: 8px solid rgba(128, 128, 128, 0);
        background-clip: padding-box;
      }
      &-thumb {
        background-color: rgba(103, 66, 154, 1);
        border: 13px solid rgba(128, 128, 128, 0);
        border-radius: 32px;
        background-clip: padding-box;
      }
      &-corner {
        display: none;
      }
      &-button {
        background-size: 80% 90%;
        background-repeat: no-repeat;
        height: 32px;
        width: 48px;
        background-color: rgba(233, 233, 233, 1);
        background-clip: padding-box;
        border-left: 8px solid rgba(128, 128, 128, 0);
        border-right: 8px solid rgba(128, 128, 128, 0);
        &:start {
          display: block;
        }
        &:end {
          display: block;
        }
        &:vertical:decrement { /* Up */
          background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" preserveAspectRatio="none"><path d="M45.66,43.5a3,3,0,0,1-2.6,4.5H4.94a3,3,0,0,1-2.6-4.5l19.06-33a3,3,0,0,1,5.2,0Z" fill="rgba(103, 66, 154, 1)" /></svg>');
          border-top: 8px solid rgba(128, 128, 128, 0);
          background-position: center bottom;
          &:start {
            border-top-left-radius: 32px;
            border-top-right-radius: 32px;
          }
          &:end {
            display: none;
          }
        }
        &:vertical:increment { /* Down */
          background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" preserveAspectRatio="none"><path d="M45.66,4.5A3,3,0,0,0,43.07,0H4.94a3,3,0,0,0-2.6,4.5l19.06,33a3,3,0,0,0,5.2,0Z" fill="rgba(103, 66, 154, 1)" /></svg>');
          border-bottom: 8px solid rgba(128, 128, 128, 0);
          background-position: center top;
          &:start {
            display: none;
          }
          &:end {
            border-bottom-left-radius: 32px;
            border-bottom-right-radius: 32px;
          }
        }
      }
    }
  }

  .passage-text {
    &::-webkit-scrollbar {
      width: 48px;
      &-track {
        background-color: rgba(255, 255, 255, 1);
        border-left: 8px solid rgba(128, 128, 128, 0);
        border-right: 8px solid rgba(128, 128, 128, 0);
        background-clip: padding-box;
      }
      &-thumb {
        background-color: rgba(103, 66, 154, 1);
        border: 13px solid rgba(128, 128, 128, 0);
        border-radius: 32px;
        background-clip: padding-box;
      }
      &-corner {
        display: none;
      }
      &-button {
        background-size: 80% 90%;
        background-repeat: no-repeat;
        height: 32px;
        width: 48px;
        background-color: rgba(255, 255, 255, 1);
        background-clip: padding-box;
        border-left: 8px solid rgba(128, 128, 128, 0);
        border-right: 8px solid rgba(128, 128, 128, 0);
        &:start {
          display: block;
        }
        &:end {
          display: block;
        }
        &:vertical:decrement { /* Up */
          background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" preserveAspectRatio="none"><path d="M45.66,43.5a3,3,0,0,1-2.6,4.5H4.94a3,3,0,0,1-2.6-4.5l19.06-33a3,3,0,0,1,5.2,0Z" fill="rgba(103, 66, 154, 1)" /></svg>');
          border-top: 8px solid rgba(128, 128, 128, 0);
          background-position: center bottom;
          &:start {
            border-top-left-radius: 32px;
            border-top-right-radius: 32px;
          }
          &:end {
            display: none;
          }
        }
        &:vertical:increment { /* Down */
          background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" preserveAspectRatio="none"><path d="M45.66,4.5A3,3,0,0,0,43.07,0H4.94a3,3,0,0,0-2.6,4.5l19.06,33a3,3,0,0,0,5.2,0Z" fill="rgba(103, 66, 154, 1)" /></svg>');
          border-bottom: 8px solid rgba(128, 128, 128, 0);
          background-position: center top;
          &:start {
            display: none;
          }
          &:end {
            border-bottom-left-radius: 32px;
            border-bottom-right-radius: 32px;
          }
        }
      }
    }
  }

    &::-webkit-scrollbar {
        &-thumb {
          background-color: rgba(103, 66, 154, 1);
        }
        &-button {
            &:vertical:decrement { /* Up */
                background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" preserveAspectRatio="none"><path d="M45.66,43.5a3,3,0,0,1-2.6,4.5H4.94a3,3,0,0,1-2.6-4.5l19.06-33a3,3,0,0,1,5.2,0Z" fill="rgba(103, 66, 154, 1)" /></svg>');
            }
            &:vertical:increment { /* Down */
                background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" preserveAspectRatio="none"><path d="M45.66,4.5A3,3,0,0,0,43.07,0H4.94a3,3,0,0,0-2.6,4.5l19.06,33a3,3,0,0,0,5.2,0Z" fill="rgba(103, 66, 154, 1)" /></svg>');
            }
        }
    }

}
