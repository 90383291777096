@import "src/app/styles/variables.module";

.home-page-header {
  .link-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    #profile-icon {
      height: 60px;
    }

    .tabs_tab {
      &:active, &:hover {
        border-bottom: none;
      }
    }
    
  }
}

.flex-button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.button-border {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-size: 27px;
  line-height: 27px;
  font-family: 'OpenSans';
  font-weight: bold;
  padding: 10px 12px;
  border: 2px solid #fff;
  border-radius: 10px;
  .star {
    height: 30px;
    width: 30px;
    margin-right: 10px;
    background: var(--star);
  }
  &:hover, &:focus, &:active, &.active {
    color: $theme-c-cello;
    background: #fff;
    .star {
      background: var(--hover-star);
    }
  }
}