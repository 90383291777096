.bestmeal {
  background: #EE7353;

  .container {
    opacity: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 25px;
    background: #DD5740;
  }

  .title-screen {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    height: 100%;
    overflow: hidden;
  }

  .title-background {
    width: 100%!important;
    height: 100%!important;
    opacity: 1;
    object-fit: cover;
  }

  .title-phone {
    position: absolute;
    left: 50%;
  }

  .phone-screen {
    bottom: 0;
  }

  .activity-card {
    display: none;
  }

  .bestmeal-header, .bestmeal-left, .bestmeal-right {
    opacity: 0;
  }

  &-burger {
    position: absolute;
    z-index: 1;
    top: 20px;
    left: 0;
  }

  &-dots {
    position: absolute;
    top: 49px;
    left: calc(41% - 64px);
  }
  
  &-header {
    margin-bottom: 15px;
    width: 100%;
    img {
      width: 100%;
    }
  }
  
  &-right {
    order: 2;
  }
  
  .passage, .question {
    order: 1;
    width: 58%;
    height: 430px;
    border-radius: 10px 10px 0 0;
    background-color: #fff;
    padding: 25px 35px 40px 35px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &-title {
      padding-bottom: 30px;
      font-family: 'OpenSans Bold';
      font-size: 27px;
      line-height: 40px;
    }
  }
  
  .question {
    padding-top: 70px;
  }

  .choices {
    display: flex;
    justify-content: space-between;
    padding: 44px 0 30px 0;
    height: 100%;
    .answers {
      background: #fff;
      border-radius: 15px;
    }
    .prompt {
      padding: 15px 35px;
      background: #FFB2A5;
      border-radius: 15px 15px 0 0;
    }
    .article {
      background: #fff;
      margin-top: 45px;
      border-radius: 15px;
      padding: 137px 22px 26px 40px;
    }
  }


  .answer, .answer-item {
    &-label {
      background: #FFB2A5;
    }
    
    &-button {
      background: #FFB2A5;
    }
    
    &-button:hover, &-button.selected {
      background: #41549B;
      color: #fff;
      .answer-label {
        background: #41549B;
        color: #fff;
      }
    }

  }
  
  .feedback-card {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    .bestmeal-left, .bestmeal-right {
      width: 19%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 90px;
      img {
        width: 100%;
      }
    }
    .feedback {
      width: 59%;
      margin: 65px 20px 25px 20px;
      padding: 40px;
      height: 68%;
      border-radius: 15px;
      position: relative;
      opacity: 0;
      &:before {
        content: '';
        position: absolute;
        top: 16px;
        right: 17px;
        width: 45px;
        height: 9px;
        background-image: url('images/three-dots.svg'); 
      }
      &:after {
        content: '';
        position: absolute;
        bottom: 12px;
        left: 12px;
        width: 88px;
        height: 21px;
        background-image: url('images/like-comment-share.svg'); 
      }
      &-icon {
        padding-bottom: 15px;
      }
      &-title {
        font-size: 27px;
        line-height: 37px;
      }
      &-text {
      font-size: 22px;
      line-height: 33px;
      }
    }
  }

  .animation-background, .animation-background-out {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: #FFCDC2;
    z-index: 2;
    pointer-events: none;
    opacity: 0.7;
  }

  .feedback-animation, .feedback-animation-out {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 3;
    .feedback-plate {
      position: absolute;
      &:nth-child(1) {
        top: -46%;
        left: -11%;
      }
      &:nth-child(2) {
        top: 4%;
        left: -26%;
      }
      &:nth-child(3) {
        top: 35%;
        left: -46%;
      }
    }
  }

  .feedback-animation, .animation-background, .feedback-animation-out {
    opacity: 0;
  }

  .feedback-next-container {
    position: absolute;
    background: #fff;
    order: 0;
    width: calc(59% - 40px);
    left: calc(20% + 25px);
    height: 20%;
    bottom: 0;
    border-radius: 15px 15px 0 0;
    padding-top: 30px;
    text-align: center;
    opacity: 0;
    &:before {
      content: '';
      position: absolute;
      top: 16px;
      right: 17px;
      width: 45px;
      height: 9px;
      background-image: url('images/three-dots.svg'); 
    }
  }
  
  .next-button {
    background: #FFCF50;
    color: #000;
    font-family: 'OpenSans';
    padding: 10px 25px;
    border-radius: 20px;
    font-size: 24px;
    line-height: 24px;
    &:hover, &:focus {
      background: #7A5000;
      color: #fff
    }
  }

  &.multiple-choice {
    .passage-container, .question-text, .article > span, ul.answers-container {
      &::-webkit-scrollbar {
        &-thumb {
          background-color: rgba(221, 87, 64, 1);
        }
        &-button {
          &:vertical:decrement { /* Up */
            background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" preserveAspectRatio="none"><path d="M45.66,43.5a3,3,0,0,1-2.6,4.5H4.94a3,3,0,0,1-2.6-4.5l19.06-33a3,3,0,0,1,5.2,0Z" fill="rgba(221, 87, 64, 1)" /></svg>');
          }
          &:vertical:increment { /* Down */
            background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" preserveAspectRatio="none"><path d="M45.66,4.5A3,3,0,0,0,43.07,0H4.94a3,3,0,0,0-2.6,4.5l19.06,33a3,3,0,0,0,5.2,0Z" fill="rgba(221, 87, 64, 1)" /></svg>');
          }
        }
      }
    }
  }

  &::-webkit-scrollbar {
    &-track {
      background-color: rgba(255, 255, 255, 1);
    }
    &-thumb {
      background-color: rgba(221, 87, 64, 1);
    }
    &-button {
      background-color: rgba(255, 255, 255, 1);
      &:vertical:decrement { /* Up */
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" preserveAspectRatio="none"><path d="M45.66,43.5a3,3,0,0,1-2.6,4.5H4.94a3,3,0,0,1-2.6-4.5l19.06-33a3,3,0,0,1,5.2,0Z" fill="rgba(221, 87, 64, 1)" /></svg>');
      }
      &:vertical:increment { /* Down */
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" preserveAspectRatio="none"><path d="M45.66,4.5A3,3,0,0,0,43.07,0H4.94a3,3,0,0,0-2.6,4.5l19.06,33a3,3,0,0,0,5.2,0Z" fill="rgba(221, 87, 64, 1)" /></svg>');
      }
    }
  }

}