.error-screen {
	background-color: #eae2dd;
	height: 100%;
}

.modal .error-screen {
	position: absolute;
	z-index: 10;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	border-radius: 20px;
	max-width: 624px;
	max-height: 500px;
	margin: auto;
	.error-footer {
		border-radius: 0 0 20px 20px;
		height: 23%;
	}
	.error-content {
		height: 77%;
	}
}

.error-content {
	height: 74%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	.error-title {
		font-family: 'OpenSans ExtraBold';
		font-size: 72px;
    line-height: 98px;
    color: #14405C;
		margin-bottom: 10px;
	}
	.error-dude {
    height: 127px;
    margin-left: 10px;
	}
	.error-message {
		width: 425px;
		margin-bottom: 25px;
    padding: 15px;
    background: #fff;
		font-family: 'OpenSans';
		font-style: normal;
		font-weight: 400;
		font-size: 22px;
		line-height: 33px;
		position: relative;
		&:after {
			content: "\A";
			border-style: solid;
			border-width: 17px;
			border-color: #FFF transparent transparent #FFF;
			position: absolute;
			left: 122px;
			bottom: -34px;
		}
	}
}

.error-button {
	font-family: 'OpenSans';
	font-style: normal;
	font-weight: 700;
	font-size: 22px;
	line-height: 30px;
	color: #EAE2DD;
	border: 2px solid #EAE2DD;
	padding: 10px 20px;
	border-radius: 10px;
	&:hover, &:focus {
		color: #14405C;
		background: #EAE2DD;
	}
}

.error-footer {
	background-color: #14405C;
	height: 26%;
	padding-top: 34px;
	text-align: center;
}