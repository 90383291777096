@import "src/app/styles/variables.module";

.studentPage {
    display: flex;
    overflow-x:auto;

    .helperSide {
        flex-grow: 1;
        flex-basis: 50%;
        padding-left: 5%;

        .studentsDropdown {
            padding-top: 40px;

            width: 400px;
            height: 100px;
            
            .selectDropdownButton {
                width: 400px;
                height: 40px;

                background-color: white;

                border-bottom: 3px solid $theme-reporting-color;
                background-color: #eae2dd;
                padding-left: 10px; 
            }
        }

        .streamsDropdown {
            width: 100%;
            display: inline-block;
            height: 200px;
            
            .splitDropdownButton {
                background-color: white;
                border: 3px solid $theme-reporting-color;
                width: 400px;
                height: 40px;

                padding-left: 10px;
            }
        }

    }

    .stats-box {
        flex-grow: 1;
        flex-basis: 50%;
    }

    .charts {
        padding-left: 10px;
        overflow-y:auto;
    }
    
}