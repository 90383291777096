@import "src/app/styles/variables.module";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import "~bootstrap/scss/dropdown";


.datePicker {
    display: inline-block;
    margin-left: 40px;
    width: 50%;
    height: 33px;

    .dateField {
        display: inline-block;
        background-color: inherit;
        width: 300px;
        height: 100%;

        .dateRangeDropdown {
            display: inline-block;
            width: 100%;
            height: 100%;

            .squared:after { content: none }

            .dropdown-toggle {
                display: inline-block;
                width: 100%;
                height: 100%;

                background-color: inherit;
                border: none;
                font-family: OpenSans;
                font-size: 24px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                color: $theme-reporting-color;
            }
        }
    }

    .timeShifts {
        display: inline-block;
        margin-left: 20px;

        .leftButton {
            vertical-align: middle;
            width: 33px;
            height: 20px;
            margin: 6px 0 6px 4px;
            transform: rotate(90deg);
        }

        .rightButton {
            vertical-align: middle;
            width: 33px;
            height: 20px;
            margin: 6px 0 6px 4px;
            transform: rotate(-90deg);
        }
    }
}
