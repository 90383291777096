.go-container {
  display: flex;
  align-items: flex-end;
  min-width: 150px;

  .jello-horizontal {
    animation: jello-horizontal 0.9s both;
  }

  .go-btn {
    justify-content: center;
    font-family: OpenSans Bold, sans-serif;
    background: #14405b;
    width: 130px;
    height: 130px;
    font-size: 3em;
    font-weight: bold;
    margin-bottom: 100px;
    cursor: pointer;
    color: #fff;
    text-transform: uppercase;
    border-radius: 50%;
    border: 4px solid #fff;
    transform: matrix(1, 0, 0, 1, 0, 0);
    padding: 25px;

    &.disabled{
      background: lightgrey;
    }

    &:hover {
      width: 150px;
      height: 150px;
      font-size: 4em;
    }
  }
}
