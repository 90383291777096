
.reporting-page-header {
  .link-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    li {
      margin: 0;
    }
  }

  .reporting-back-button {
    transform: rotate(90deg);
  }

  #reporting-back-ancor {
    position: absolute;
    left: 10px;
    cursor: pointer;
  }
}
