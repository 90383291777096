@import "src/app/styles/variables.module";

.searchWordsWrapper {
    font-family: 'FontAwesome';
    position: relative;

    .searchWords {
        position: relative;
        height: 35px;
        width: 100%;
        font-size: 14px;
        font-weight: 300;
        
        border: 3px solid $theme-reporting-color;

        background-color: white;
        background-image: url("../../../shared/images/teacher/icon_search.svg");
        background-size: 32px 32px;
        background-repeat: no-repeat;
        background-position: 99% 100%;

        &.disabled {
            background-color: lightgrey;
        }
    }

}
    