.progress-bar {
  width: 160px;
  height: 14px;
  background: #fff;
  position: relative;
  overflow: hidden;

  &-level {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    border: 2px solid #fff;
    background: #213f5a;
  }
}
