.undelivered {
	background-color: #000;
	.container {
		background-image: url('images/houses.svg'); 
	}
	.activity-card {
		display: flex;
		transform: none;
	}

	.passage {
		background-image: url('images/letter.svg');
    background-size: 100%;
    background-repeat: no-repeat;
		opacity: 0;
    width: 75%;
    height: 540px;
    margin: 40px auto 100px auto;
		padding: 60px 65px 45px 60px;
		position: absolute;
    top: 0;
    left: 0;
    right: 0;
	}

	.question {
		background-image: url('images/postmark.svg');
    background-size: 100%;
    background-repeat: no-repeat;
		width: 60%;
    height: 196px;
    margin: auto;
    padding: 20px 60px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    &-text {
      display: flex;
      span {
        margin: auto;
      }
    }
	}

	.passage-title {
    margin-bottom: 45px;
    font-weight: normal;
    font-size: 22px;
    line-height: 33px;
	}

	.passage-second-title {
		margin-top: 45px;
		font-size: 22px;
	}

	.choices {
    display: flex;
    justify-content: space-between;
		padding: 44px 30px 30px 30px;
		height: 100%;
    .answers {
      background: #fff;
      border-radius: 15px;
    }
    .prompt {
      padding: 15px 35px;
      background: #D6CCFA;
			border-radius: 15px 15px 0 0;
    }
    .article {
      background: #fff;
      margin-top: 45px;
      padding: 45px 30px 30px 30px;
			position: relative;
			&:before, &:after {
				content: '';
				width: calc(100% - 20px);
				height: 10px;
				background-image: url('images/post_border_top.svg');
				background-repeat: no-repeat;
				position: absolute;
				left: 10px;
			}
			&:before {
				top: 10px;
			}
			&:after {
				bottom: 10px;
				transform: scaleX(-1);
				background-position-x: right;
			}
    }
  }

  .answer, .answer-item  {
    &-label {
      background: #D6CCFA;
    }
    
    &-button {
      background: #D6CCFA;
    }
    
    &-button:hover, &-button.selected {
      background: #1B2644;
      color: #fff;
      .answer-label {
        background: #1B2644;
        color: #fff;
      }
    }
  }

	.next-button {
		background: #FF6347;
    font-family: "OpenSans";
    padding: 10px 25px;
    border-radius: 20px;
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 0;
    position: absolute;
		bottom: -60px;
    left: 50%;
    transform: translate(-50%);
    &:hover, &:focus {
      background: #A0070F;
      color: #fff
    }
  }

	.feedback {
    background-size: 100%;
    background-repeat: no-repeat;
		background-color: transparent;
		width: 75%;
    height: 343px;
    margin: 130px auto;
    padding: 50px 60px 50px 60px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
		flex-direction: row;
		&-icon {
			margin-right: 25px;
		}
		&.correct {
			background-image: url('images/correct.svg');
		}
		&.incorrect {
			background-image: url('images/incorrect.svg');
		}
	}

	.feedback-card {
		height: 100%;
		display: block;
		.feedback-next-container {
			position: absolute;
			bottom: 20%;
			left: 50%;
			transform: translateX(-50%);
		}
		.next-button {
			position: static;
			transform: none;
		}
	}

	.feedback-animation {
		z-index: 1;
		position: absolute;
		width: 100%;
		pointer-events: none;
	}

  &.multiple-choice {
    .passage-container, .article > span, ul.answers-container {
      &::-webkit-scrollbar {
        &-track {
          background-color: rgba(214, 204, 250, 1);
        }
        &-thumb {
          background-color: rgba(27, 38, 68, 1);
        }
        &-button {
          background-color: rgba(214, 204, 250, 1);
          &:vertical:decrement { /* Up */
            background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" preserveAspectRatio="none"><path d="M45.66,43.5a3,3,0,0,1-2.6,4.5H4.94a3,3,0,0,1-2.6-4.5l19.06-33a3,3,0,0,1,5.2,0Z" fill="rgba(27, 38, 68, 1)" /></svg>');
          }
          &:vertical:increment { /* Down */
            background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" preserveAspectRatio="none"><path d="M45.66,4.5A3,3,0,0,0,43.07,0H4.94a3,3,0,0,0-2.6,4.5l19.06,33a3,3,0,0,0,5.2,0Z" fill="rgba(27, 38, 68, 1)" /></svg>');
          }
        }
      }
    }
    .question-text {
      &::-webkit-scrollbar {
        &-track {
          background-color: rgba(255, 255, 255, 1);
        }
        &-thumb {
          background-color: rgba(27, 38, 68, 1);
        }
        &-button {
          background-color: rgba(255, 255, 255, 1);
          &:vertical:decrement { /* Up */
            background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" preserveAspectRatio="none"><path d="M45.66,43.5a3,3,0,0,1-2.6,4.5H4.94a3,3,0,0,1-2.6-4.5l19.06-33a3,3,0,0,1,5.2,0Z" fill="rgba(27, 38, 68, 1)" /></svg>');
          }
          &:vertical:increment { /* Down */
            background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" preserveAspectRatio="none"><path d="M45.66,4.5A3,3,0,0,0,43.07,0H4.94a3,3,0,0,0-2.6,4.5l19.06,33a3,3,0,0,0,5.2,0Z" fill="rgba(27, 38, 68, 1)" /></svg>');
          }
        }
      }
    }
  }

  &::-webkit-scrollbar {
    &-track {
      background-color: rgba(214, 204, 250, 1);
    }
    &-thumb {
      background-color: rgba(27, 38, 68, 1);
    }
    &-button {
      background-color: rgba(214, 204, 250, 1);
      &:vertical:decrement { /* Up */
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" preserveAspectRatio="none"><path d="M45.66,43.5a3,3,0,0,1-2.6,4.5H4.94a3,3,0,0,1-2.6-4.5l19.06-33a3,3,0,0,1,5.2,0Z" fill="rgba(27, 38, 68, 1)" /></svg>');
      }
      &:vertical:increment { /* Down */
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" preserveAspectRatio="none"><path d="M45.66,4.5A3,3,0,0,0,43.07,0H4.94a3,3,0,0,0-2.6,4.5l19.06,33a3,3,0,0,0,5.2,0Z" fill="rgba(27, 38, 68, 1)" /></svg>');
      }
    }
  }

}