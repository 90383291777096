.multiple-choice {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-family: 'OpenSans ', sans-serif;
  color: #000;

  .container {
    width: 100%;
    max-width: 1024px;
    height: 680px;
    position: relative;
    overflow: hidden;
    margin: auto 0;
  }
  
  .passage, .question {
    &-title {
      font-size: 22px;
      line-height: 33px;
      font-weight: normal;
    }
    &-text {
      font-size: 22px;
      line-height: 33px;
      margin-bottom: 10px;
      b {
        font-family: 'OpenSans Bold';
      }
    }
  }

  .prompt {
    font-size: 22px;
    line-height: 33px;
    font-weight: 400;
    b {
      font-family: 'OpenSans Bold';
    }
  }
  
  .back-button {
    width: 70px;
    height: 70px;
    position: absolute;
    z-index: 10;
    left: 10px;
    top: 10px;
  }
  
  .article {
    width: calc(41% - 8px);
    font-size: 22px;
    line-height: 33px;
    display: flex;
    b {
      font-family: 'OpenSans Bold';
    }
  }
  
  .answers {
    width: calc(59% - 8px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    li {
      list-style: none;
    }
    input {
      opacity: 0;
      position: absolute;
      bottom: 0;
      right: 0;
      width: calc(100% + 18px);
      height: calc(100% + 7px);
    }
  }
  
  .answer-label {
    position: absolute;
    top: -15px;
    left: -25px;
    width: 52px;
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    border-radius: 50%;
    border: 3px solid #fff;
  }
  
  .answer-button {
    width: 100%;
    min-height: 118px;
    margin-bottom: 20px;
    display: block;
    font-size: 22px;
    line-height: 33px;
    padding: 12px 14px 16px 40px;
    text-align: left;
    border-radius: 15px;
    position: relative;
    font-weight: 400;
    font-family: 'OpenSans';
    * {
      cursor: pointer;
    }
  }
  
  .answers-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px 50px 30px 60px;
  }

  .focused {
    outline: 3px solid transparent !important;
    box-shadow: 0 0 0 3px #1916ff, 0 0 0 5px #ffffff !important;
  }

  .next-button {
    font-family: 'OpenSans', sans-serif;
    color: #000;
    padding: 10px 25px;
    border-radius: 20px;
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 0;
    margin-top: 16px;
  }
  
  .center {
    text-align: center;
  }

  .passage-containerP, .question-text {
    height: 100%;
  }

  .feedback-title, .feedback-text b {
    font-family: 'OpenSans Bold';
  }

}

.multiple-choice, .passage-container, .question-text, .article > span, ul.answers-container, .feedback-container {
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 48px;
    &-track {
      background-color: rgba(233, 233, 233, 1);
      border-left: 8px solid rgba(128, 128, 128, 0);
      border-right: 8px solid rgba(128, 128, 128, 0);
      background-clip: padding-box;
    }
    &-thumb {
      background-color: rgba(128, 128, 128, 1);
      border: 13px solid rgba(128, 128, 128, 0);
      border-radius: 32px;
      background-clip: padding-box;
    }
    &-corner {
      display: none;
    }
    &-button {
      background-size: 80% 90%;
      background-repeat: no-repeat;
      height: 32px;
      width: 48px;
      background-color: rgba(233, 233, 233, 1);
      background-clip: padding-box;
      border-left: 8px solid rgba(128, 128, 128, 0);
      border-right: 8px solid rgba(128, 128, 128, 0);
      &:start {
        display: block;
      }
      &:end {
        display: block;
      }
      &:vertical:decrement { /* Up */
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" preserveAspectRatio="none"><path d="M45.66,43.5a3,3,0,0,1-2.6,4.5H4.94a3,3,0,0,1-2.6-4.5l19.06-33a3,3,0,0,1,5.2,0Z" fill="rgba(128, 128, 128, 1)" /></svg>');
        border-top: 8px solid rgba(128, 128, 128, 0);
        background-position: center bottom;
        &:start {
          border-top-left-radius: 32px;
          border-top-right-radius: 32px;
        }
        &:end {
          display: none;
        }
      }
      &:vertical:increment { /* Down */
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" preserveAspectRatio="none"><path d="M45.66,4.5A3,3,0,0,0,43.07,0H4.94a3,3,0,0,0-2.6,4.5l19.06,33a3,3,0,0,0,5.2,0Z" fill="rgba(128, 128, 128, 1)" /></svg>');
        border-bottom: 8px solid rgba(128, 128, 128, 0);
        background-position: center top;
        &:start {
          display: none;
        }
        &:end {
          border-bottom-left-radius: 32px;
          border-bottom-right-radius: 32px;
        }
      }
    }
  }
}

.feedback-container::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.7);
}
.feedback-container::-webkit-scrollbar-button {
  background-color: rgba(255, 255, 255, 0.7);
}

#feedback-correct .feedback-container {
  &::-webkit-scrollbar {
    &-thumb {
      background-color: rgba(0, 163, 90, 1);
    }
    &-button {
      &:vertical:decrement { /* Up */
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" preserveAspectRatio="none"><path d="M45.66,43.5a3,3,0,0,1-2.6,4.5H4.94a3,3,0,0,1-2.6-4.5l19.06-33a3,3,0,0,1,5.2,0Z" fill="rgba(0, 163, 90, 1)" /></svg>');
      }
      &:vertical:increment { /* Down */
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" preserveAspectRatio="none"><path d="M45.66,4.5A3,3,0,0,0,43.07,0H4.94a3,3,0,0,0-2.6,4.5l19.06,33a3,3,0,0,0,5.2,0Z" fill="rgba(0, 163, 90, 1)" /></svg>');
      }
    }
  }
}
#feedback-incorrect .feedback-container {
  &::-webkit-scrollbar {
    &-thumb {
      background-color: rgba(199, 123, 0, 1);
    }
    &-button {
      &:vertical:decrement { /* Up */
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" preserveAspectRatio="none"><path d="M45.66,43.5a3,3,0,0,1-2.6,4.5H4.94a3,3,0,0,1-2.6-4.5l19.06-33a3,3,0,0,1,5.2,0Z" fill="rgba(199, 123, 0, 1)" /></svg>');
      }
      &:vertical:increment { /* Down */
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" preserveAspectRatio="none"><path d="M45.66,4.5A3,3,0,0,0,43.07,0H4.94a3,3,0,0,0-2.6,4.5l19.06,33a3,3,0,0,0,5.2,0Z" fill="rgba(199, 123, 0, 1)" /></svg>');
      }
    }
  }
}
