@import "src/app/styles/variables.module";

.dropdown-menu {
    display: none;
}

.dropdown-menu.open {
    display: block;
    background-color: white;
    position:fixed;
    
    min-width: 300px;
    max-height: 200px;
    overflow: scroll;

    overflow-x: hidden;
    text-overflow: ellipsis;
    border: 2px solid $theme-reporting-color;

    z-index: 20;

    :hover {
        background-color: lightgrey;
    }

    button {
        display: block;
        height: 30px;
        line-height: 30px;
        width: 100%;
        white-space: nowrap;
        color: $theme-reporting-color;

        overflow: hidden;
        text-overflow: ellipsis; 
        white-space: nowrap;
        text-align: left;

        font-size: 16px;

        border-bottom: 1px solid black;
        margin-left: 10px;
    }

    button:last-child {
        border-bottom: 0;
    }
}

#selectDropdown {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    text-align:left;
    
    min-width: 100px;
    width: 100%;

    &.disabled {
        background-color: lightgrey;
    }

    .selectName {
        font-size: 18px;
        font-weight: 300;
        color: $theme-reporting-color;
        font-family: 'OpenSans Bold';
        list-style: none;
    
        overflow: hidden;
        white-space:nowrap;
        text-overflow:ellipsis;
    
        width: calc(100% - 40px); // 40px is width of carret
        display:inline-block;
    
        margin: auto;
        vertical-align: middle;
        line-height: 30px;
    }

    .carret {
        padding-right: 5px;
        float: right;
        height: 40%;
    }  
}

.dropdown-menu::-webkit-scrollbar {
    width: 8px;
}

.dropdown-menu::-webkit-scrollbar-thumb {
    border-radius: 0px;
    border: 6px solid $theme-reporting-color;
    background-color: white;
    background-clip: content-box;
}