@import "src/app/styles/variables.module";

.charts {
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  padding-left: 10px;
  padding-right: 40px;
  overflow-y: auto;

  .chart-switcher {
    min-width: 600px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .left-arrow {
      svg {
        transform: rotate(180deg);
      }
    }

    .chart-type {
      button {
        padding: 0;
        font-family: OpenSans, serif;
        font-size: 24px;
        color: $theme-reporting-color;

        &:active, &:hover, &:focus, &.active {
          border-bottom: 5px solid $theme-reporting-color;
          font-weight: bold;
        }
      }
    }
  }
}
