.risingtides {
  display: flex;
  flex-direction: column;
	background-color: #B7BDC0;

	.container {
		background-image: url('images/sky-background.svg');
	}

  &-directions {
    position: absolute;
  }

  .intro-part {
    display: block;
    font-size: 22px;
    margin-bottom: 49px;
  }

  .passage-text {
    display: none;
    opacity: 0;
    background: #ED98D3;
    border-radius: 15px;
    width: 44%;
    padding: 68px 49px 0 49px;
    height: calc(100% - 110px);
    position: absolute;
    left: 25px;
    top: 85px;
    &::before {
      content: url('images/icon_idea.svg');
      position: absolute;
      top: -36px;
      left: calc(50% - 36px);
    }
  }

  .next-button {
    background: #fff;
    color: #000;
    &:hover {
      background: #0072B2;
      color: #fff;
    }
  }

  .explanation-next {
    display: none;
    opacity: 0;
    .next-button {
      position: absolute;
      bottom: 80px;
      opacity: 1;
      left: calc(22% - 26px);
    }
  }
  
  .rising-transition {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 3;
    pointer-events: none;
  }

  #risingtides-transition {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    pointer-events: none;
    display: none;
  }

  .dropzones {
    position: absolute;
    width: 100%;
    display: flex;
    bottom: 16px;
    justify-content: space-around;
    padding: 0 195px 0 50px;
  }

  .dropzone {
    position: relative;
    .box {
      top: 9px;
      left: 5px;
      position: absolute;
    }
  }

  .boxes {
    position: absolute;
    width: 100%;
    height: 290px;
    display: flex;
    top: 76px;
    padding: 0 25px;

    .dropzone {
      &:nth-child(odd) {
        align-self: flex-end;
      }
      &:nth-child(3) {
          margin-right: 180px; 
      }
    }

    &.eightOptions {
      .dropzone {
        &:nth-child(4n) {
          position: absolute;
          top: 188px;
          left: 159px;
        }
        &:nth-child(8) {
          left: auto;
          right: 146px;
        }
      }
    }

    .box {
      top: 0;
      left: 0;
    }
  }

  .words-modal-wrapper {
    border: 15px solid #ed98d3;
  }

  .activity-word {
    position: absolute;
    display: inline-block;
    top: calc(50% + 60px);
    left: 50%;
    transform: translate(-50%);
    background: #990062;
    color: #fff;
    font-size: 20px;
    font-family: 'OpenSans Bold';
    padding: 5px 10px;
    border-radius: 15px;
  }

  .box {
    position: relative;
    text-align: center;
    align-self: flex-start;
    font-family: 'OpenSans Bold';
    font-size: 20px;
    color: #FFFFFF;
    .word {
      position: absolute;
      display: inline-block;
      margin: auto;
      top: 55%;
      left: 50%;
      transform: translate(-50%);
      background: #004578;
      border-radius: 20px;
      padding: 8px 15px;
      line-height: 22px;
    }
  }

  .question .next-button {
    position: absolute;
    bottom: 55px;
    right: 70px;
  }

  .info-text {
    position: absolute;
    top: 23px;
    left: 50%;
    transform: translateX(-50%);
    width: 42%;
    font-size: 22px;
    line-height: 30px;
    padding: 17px 16px 17px 50px;
    background: #ED98D3;
    border-radius: 20px;
    z-index: 1;
  }

  .info-icon {
    position: absolute;
    top: -13px;
    left: -32px;
  }

  .drop-animated {
    width: 146px;
    height: 198px;
  }

  .drop-glow {
    position: absolute;
    width: 200px;
    height: 250px;
    top: -29px;
    left: -27px;
  }
}

.preview-box {
  position: relative;
  text-align: center;
  align-self: flex-start;
  font-family: 'OpenSans Bold';
  font-size: 20px;
  color: #FFFFFF;
  .word {
    position: absolute;
    display: inline-block;
    margin: auto;
    top: 55%;
    left: 50%;
    transform: translate(-50%);
    background: #004578;
    border-radius: 20px;
    padding: 8px 15px;
    line-height: 22px;
  }

  .feedback-next-container .next-button {
    bottom: 180px;
    left: 50%;
    transform: translateX(-50%);
    right: auto;
  }
}