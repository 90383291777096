.feedback {
  background: #FFDD71;
  line-height: 33px;
  font-size: 22px;
  display: flex;
  flex-direction: column;

  &-title {
    margin-bottom: 15px;
    font-size: 27px;
  }

  &-container {
    display: flex;
    flex-direction: column;
  }

  &.correct {
    background: #77FF73;
  }

  &-icon {
    text-align: center;
    img {
      width: 80px;
      height: 80px;
    }
  }
}

