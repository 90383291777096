.mastered-words-modal-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  max-height: 100%;
  max-width: 100%;
  width: 830px;
  height: 472px;

  background-color: #14405c;
  border-radius: 30px;
}
