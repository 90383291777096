
$num_cols: 1;

.reporting {
    .reportingPage {
        .centeredPage {
            tbody td, thead th {
                // divided by num cols
                width: calc(100% / $num_cols);
                float: left;
            }
        }
    }
}
