.session-modal-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 10;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-color: rgba(247, 247, 247, 0.7);
}

.session-modal {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 720px;
  height: 515px;
  border-radius: 32px;
  cursor: default;
  background-color: #fff;
  .close-button {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 50px;
    height: 50px;
    background-color: transparent;
    border: none;
    box-shadow: none;
    cursor: pointer;
  }
  .session-modal-header {
    font-family: 'Quicksand-SemiBold';
    margin-bottom: 20px;
    padding: 0px;
    font-size: 43px;
    font-weight: 400;
    text-align: center;
    color: #393434;
  }
  .session-modal-prompt {
    font-family: 'Quicksand-Medium';
    margin-bottom: 45px;
    padding: 0px;
    font-size: 26px;
    text-align: center;
    color: #393434;
  }
  .button {
    position: relative;
    padding: 0 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Quicksand-Bold';
    width: 300px;
    height: 90px;
    border: 5px solid #006fe3;
    border-radius: 111px;
    box-shadow: none;
    font-size: 26px;
    color: #ffffff;
    cursor: pointer;
    transform-origin: center center;

    &.back-button {
      box-shadow: 0px 3px 4px #cad3e4, 0px 3px 0px #006fe3;
      background-color: #006fe3;
      margin-bottom: 25px;
      &:hover {
        background-color: #014c9c;        ;
        border-color: #014c9c;        ;
      }
    }

    &.logout-button {
      border-style: solid;
      box-shadow: 0px 3px 4px #cad3e4, 0px 3px 0px transparent;
      background-color: transparent;
      color: #006fe3;
      &:hover {
        background-color: #e1e8f9;
      }
    }
  }
  *:focus {
    outline: 3px solid transparent !important;
    box-shadow: 0 0 0 3px #9c0d63, 0 0 0 5px #ffffff !important;
  }
}