@import "src/app/styles/variables.module";

$num_cols: 6;

.reportingPage {
    .studentsPage {
        .centeredPage {
            thead {
                tr {
                    cursor: pointer;
                }
            }

            .optionsHeader {
                .dropdown-wrapper {
                    display: inline-block;
                    width: 25%;

                    .selectDropdownButton {
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        appearance: none;
    
                        background-color: white;
    
                        border: 3px solid $theme-reporting-color;
                        text-align:left;
                        padding-left: 10px;
                        vertical-align: middle;
                    }
                }
            }

            tbody {
                &.loading {
                    background-image: url('../../../images/loading_activity.gif');
                    background-position: center;
                    background-repeat: no-repeat;
                }
            }

            tbody td, thead th {
                // divided by num cols
                width: calc(100% / $num_cols);
                float: left;
            }

            .streamIcon {
                margin-right: 5px;
            }
        }
    }
}