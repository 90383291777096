@import "src/app/styles/variables.module";

.wordsPage {
    padding-top: 2%;

    overflow-y: auto;

    .reportingPage {
        padding-top: 0;

        .centeredPage {
            display: flex;
            flex-direction: column;
            flex-flow: row wrap;
            flex-direction: column;
            flex-wrap: nowrap;
            flex-grow: 1;
    
            justify-content: flex-start;
    
            align-self: center;
    
            margin: auto;
    
            max-height: 100%;
            height: 100%;
    
            max-width: 90%;
            width: 90%;
    
            overflow: auto;
        }
    }


    tbody {
        &.loading {
            background-image: url('../../images/loading_activity.gif');
            background-position: center;
            background-repeat: no-repeat;
        }
    }
}
