
.subnav {
    display: flex;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .tabs_tab-container {
        display: inline;
        float: left;
    }
    .tabs_tab {
        font-size: 1.8em;
        margin: 0 3em;
        cursor: pointer;
        font-family: OpenSans, serif;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #fff;

        &.active, &:active, &:hover {
            border-bottom: 0.2em solid white;
        }
    }
}
