.words-modal-wrapper {
  z-index: 100;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: row;
  flex: 1;

  max-height: 100%;
  max-width: 100%;
  height: 500px;
  width: 854px;
  border: 15px solid #FFEB41;
  border-radius: 10px;

  .words-modal {
    display: flex;
    flex-direction: row;

    border: 15px solid grey;

    background-color: white;

    width: 100%;
    height: 100%;

    &-column {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .word {
        padding: 10px;

        font-family: OpenSans, sans-serif;
        color: #000;

        .word-word {
          font-size: 22px;
          font-weight: bold;
          text-align: left;
        }

        .word-definition {
          font-size: 20px;
          text-align: left;
        }
      }
    }

    &-divider {
      width: 2px;
      background: #979797;
    }
  }

  .close-button {
    position: fixed;
    top: -38px;
    right: -43px;

    background-repeat: no-repeat;

    width: 50px;
    height: 50px;
  }
}

.fade-in {
  -webkit-animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.words-modal-icon {
  position: absolute;
  top: -36px;
  left: calc(50% - 36px);
}
