@import "src/app/styles/variables.module";

.listOption {
    
    input[type=number] {
        border: 2px solid $theme-reporting-color;
        margin-left: 10px;
        width: 50px;
        height: 25px;
    }
    
    &:last-child {
        margin-top: 30px;
    }

}
