.greatestmovie {
	background: #000;
	.title-screen {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    height: 100%;
		width: 100%;
    overflow: hidden;
  }

	.intro-background, .intro-lights, .intro-title, .intro-people {
		position: absolute;
	}
	
	.intro-lights {
		left: 0;
		right: 0;
		margin: auto;
		top: 127px;
		width: calc(100% - 40px);
	}
	
	.intro-background {
		width: 100%;
		height: 100%;
	}
	
	.intro-title {
		top: 50px;
		left: 50%;
		transform: translate(-50%);
	}
	
	.intro-people {
		bottom: 0;
		left: 0;
		width: 100%;
	}
	
	&-left, &-right {
		position: absolute;
		bottom: 0;
	}
	
	&-left {
		left: 0
	}
	
	&-right {
		right: 0
	}

	.passage {
		position: absolute;
	    width: calc(100% - 180px);
	    min-height: 376px;
	    max-height: 620px;
	    margin: auto;
	    left: 0;
	    right: 0;
	    top: 24px;
	    background: #fff;
	    padding: 3% 12%;
	    border-radius: 50px;
	    box-shadow: 0px 10px 0px #000;
		display: flex;
	    flex-direction: column;
	    justify-content: space-between;
	    &-title {
	      padding-bottom: 30px;
	      font-family: 'OpenSans Bold';
	      font-size: 27px;
	      line-height: 40px;
	    }
	}

	.greatestmovie-clapper {
		width: calc(100% - 30px);
		position: absolute;
		left: 15px;
		top: 45px;
		img {
			width: 100%;
		}
	}

	.question {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 35%;
    max-height: 50%;
    width: 60%;
    color: #fff;
	}

	.choices {
    display: flex;
    justify-content: space-between;
		padding: 44px 30px 30px 30px;
		height: 100%;
		background: #062526;
		.answers {
      background: #fff;
      border-radius: 15px;
    }
    .prompt {
      padding: 15px 35px;
      background: #4BE7F7;
      border-radius: 15px 15px 0 0;
    }
    .article {
      background: #ccc;
      margin-top: 45px;
      padding: 45px 22px 22px 40px;
    }
  }

	.answer, .answer-item  {
    &-label {
      background: #4BE7F7;
    }
    
    &-button {
      background: #4BE7F7;
    }
    
    &-button:hover, &-button.selected {
      background: #2D2D2D;
      color: #fff;
			.answer-label {
				background: #2D2D2D;
				color: #fff;
			}
    }
  }

	&-clappertop {
		width: calc(41% - 20px);
    position: absolute;
    left: 25px;
    z-index: 1;
	}

	.feedback {
    flex-direction: row;
	}

	.feedback-title {
    margin-bottom: 35px;
	}

	.feedback-animation {
		position: absolute;
	    height: 100%;
	    width: 100%;
	    top: 0;
	    left: 0;
	}

	.number {
		position: absolute;
		top: 0;
		bottom: 0;
		margin: auto;
		left: 0;
		right: 0;
		opacity: 0;
	}

	.feedback {
	    position: absolute;
	    top: 130px;
		left: 0;
	    right: 0;
	    margin: auto;
	    height: 49%;
	    width: 70%;
		padding: 30px 38px;
		&-icon {
			float: left;
			margin-right: 25px;
		}
		&-next-container {
			position: absolute;
			width: 100%;
			text-align: center;
			top: 68%;
		}
	}

	.next-button {
	    background: #00A6C7;
	    font-family: 'OpenSans';
	    padding: 10px 25px;
	    border-radius: 20px;
	    font-size: 24px;
	    line-height: 24px;
	    &:hover, &:focus {
			background: #000;
			color: #fff
	    }
	}
}