@import "src/app/styles/variables.module";

#guide {
    scroll-behavior: smooth !important;
    width: 100% !important;
    height: 100% !important;
    margin-top: 56px !important;
    overflow: auto;
    overflow-y: scroll !important;
    overflow-x: scroll !important;

    .h1, .h2, .h3, h1, h2, h3 {
      margin-top: 20px;
      margin-bottom: 10px;
      font-family: inherit;
      font-weight: 500;
      line-height: 1.1;
      color: inherit;
    }
    
    p {
      display: block;
      margin: 0 0 10px;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      box-sizing: content-box;
      color: #262626;
      font-size: 14px;
      line-height: 1.57;

      -webkit-touch-callout: none;
      -webkit-tap-highlight-color: transparent;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      -webkit-text-size-adjust: 100%;
    }
  
    hr {
      margin-top: 0;
      border: 1px solid #ebebeb;
    }
  
    .banner {
      img {
        width: 100%;
        height: auto;
      }
    }
  
    .guideHeader {
      width: 100%;
      display: flex;
      flex-direction: row;
      h1 {
        font-size: 40px;
        font-family: Amplify, sans-serif;
        color: #3b3b3b;
      }
  
      .imageWrapper {
        margin-top: -40px;
        margin-left: 5%;
        height: 115px;
      }
    }
  
    .guideBody {
      width: 80%;
      margin: 0 10%;
      overflow: hidden;
  
      .guideSection {
        float: left;
        width: 80%;
        color: #262626;
        font-size: 14px;
        line-height: 1.57;
  
        h2 {
          color: #595959;
          font-size: 30px;
          font-family: Amplify, sans-serif;
          margin: 20px 0;
        }
  
        p {
          font-family: BentonSansBook, sans-serif;
          font-weight: lighter;
        }
      }
  
      .guideDocs {
        display: flex;
        justify-content: flex-start;
  
        .guideItem {
          width: 45%;
          display: flex;
          justify-content: center;
          flex-direction: column;
          text-align: center;
  
          .imageWrapper {
            width: 100%;
            height: auto;
          }
  
          &:first-child {
            margin-right: 10%;
          }
  
          button {
            margin: 1em;
            font-size: 16px;
            font-weight: 500;
          }
        }
      }
  
      .guideContent {
        float: right;
        width: 15%;
        padding-left: 5%;
        font-family: "BentonSansMedium";
  
        h3 {
          color: #595959;
          font-size: 20px;
          font-weight: 500;
        }
  
        p {
          color: #4aa548;
          font-size: 13px;
          font-weight: 500;
          background-color: white;
          border: 0;
          cursor: pointer;
  
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  
    footer {
      width: 100%;
      padding-top: 20px;
      margin-bottom: 75px;
  
      .footerInfo {
        display: flex;
        flex-direction: row;
        font-size: 14px;
        font-family: "BentonSansMedium";
        justify-content: center;
  
        a, p {
          padding-top: 10px;
          vertical-align: middle;
          margin-left: 5px;
          text-align: center;
        }
      }
    }
  
    .orange {
      color: #f37321;
    }
  
    .logo {
      font-family: Amplify, sans-serif;
    }
  
    button {
      display: inline-block;
      font-weight: 400;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
      background-color: #4aa548;
      color: #fff;
      border: .0625em solid transparent;
      font-size: 16px;
      height: 2.5em;
      line-height: 2.375em;
      position: relative;
      border-radius: 1.25em;
      padding: 0 1.5em;
      cursor: pointer;
      font-family: "BentonSansMedium";
  
      img {
        height: 20px;
        width: auto;
        margin-left: 5px;
      }
  
      &:hover {
        background-color: #387c36;
      }
    }
  }
  