@import "src/app/styles/variables.module";

.background-opacity{
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, .5);
    opacity: 0;
    transition: opacity .2s ease;
    display: flex;
    opacity: 1;
    z-index: 9;
}

.confirmModal {
    opacity: 100%;
    overflow: scroll;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);


    max-width: 50vw;
    width: 600px;

    max-height: 50vh;
    height: 300px;

    box-sizing: border-box;
    padding: 20px 20px 35px 35px;
    background-color: white;
    border-radius: 20px;
    border: 5px solid $theme-reporting-color;
    color: black;
    font-family: Amplify;
    font-size: 16px;
    z-index: 10;

    #confirm-modal-header {
        margin-top: 20px;
    }

    #confirm-modal-description {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 70px;
    }

    button {
        width: 144px;
        height: 54px;
        border: 2px solid $theme-reporting-color;
        border-radius: 15px;
        color: white;

        font-size: 24px;
        font-weight: bold;

        line-height: 54px;
        text-align: center;

        &#confirm-modal-confirmation, &#confirm-modal-stream-change-placeholder {
            background-color: $theme-reporting-color;
            margin-left: 20px;
        }

        &#confirm-modal-cancel {
            background-color: white;
            color: $theme-reporting-color;
        }

    }
    
    h3 {
        margin: 0 10px 10px 10px;
        font-family: OpenSans;
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $theme-reporting-color;
    }

    p {
        margin: 0 10px 10px 10px;
        margin: 3px 0 19px 8px;
        font-family: OpenSans;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $theme-reporting-color;
    }


}