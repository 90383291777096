.multiple-choice .container {
	max-height: 680px;
	max-width: 1024px;
}

svg {
	pointer-events: none;
}

.doctor {
	background: #090909;

	.full-screen {
	    position: absolute;
	    left: 0;
	    top: 0;
	    height: 100%;
		width: 100%;
	    overflow: hidden;
	}

	#black-background {
		background-color: #040000;
	}

	.container {
		background-color: #040000;
		background-image: url('images/doctor_skeleton-torso-knee.svg');
	}

	#screenBg {
		background-color: #2e1926;
	}
	
	&-left, &-right {
		position: absolute;
		bottom: 0;
		height: 100%;
	}
	
	&-left {
		left: 0
	}
	
	&-right {
		right: 0
	}

	.passage {
		position: absolute;
		opacity: 0;
	    width: 45%;
	    height: 64%;
	    margin: auto;
	    left: 0;
	    right: 0;
	    top: 20%;
	    background: #fff;
	    padding: 4.5%;
	    border-radius: 12px;
		display: flex;
	    flex-direction: column;
	    justify-content: space-between;
	    &::before {
	    	content: 'Read the description of this terrible (and completely made-up) disease.';
	    	font-family: 'OpenSans', sans-serif;
	    	font-style: italic;
			font-size: 22px;
			line-height: 33px;
			position: absolute;
			left: 0;
			top: -24%;
			width: 80%;
			min-height: 80px;
			padding: 5% 10%;
			background-color: #FF5D69;
			color: #000;
			border-radius: 12px 12px 0 0;
	    }
	    &::after {
	    	content: url('images/doctor_stethescope-tilt.svg');
	    	width: 169px;
	    	height: 195px;
	    	position: absolute;
	    	top: -21%;
	    	left: -30%;
	    }
	    &-title {
	      padding: 4% 14%;
	      width: 128%;
	      border-radius: 12px 12px 0 0;
	      margin-bottom: 15px;
	      margin-left: -14%;
	      margin-top: -7%;
	      font-family: 'OpenSans Bold';
	      font-size: 24px;
	      line-height: 36px;
	    }
	    .next-button {
	    	position: absolute;
	    	top: 102%;
	    	left: 50%;
	    	transform: translateX(-50%);
	    }
	}

	.question {
	    position: absolute;
	    width: 45%;
	    height: 35%;
	    padding: 3% 6%;
	    border-radius: 12px;
	    top: 50%;
	    left: 50%;
	    transform: translate(-50%, -50%);
	    display: flex;
	    flex-direction: column;
	    justify-content: space-between;
	    color: #000;
	    background-color: #FF5D69;
	    .next-button {
	    	position: absolute;
	    	top: 110%;
	    	left: 50%;
	    	transform: translateX(-50%);
	    }
	}

	.choices {
	    display: flex;
	    justify-content: space-between;
		padding: 44px 30px 30px 30px;
		height: 100%;
		.answers {
			background: #fff;
			border-radius: 12px;
		}
		.prompt {
			padding: 15px 35px;
			background: #FF5D69;
			border-radius: 12px 12px 0 0;
		}
		.article {
			background: #fff;
			margin-top: 45px;
			padding: 137px 22px 22px 40px;
			border-radius: 12px;
		}
	    .article::after {
	    	content: url('images/doctor_stethescope.svg');
	    	width: 144px;
	    	height: 198px;
	    	position: absolute;
	    	top: -12%;
	    	left: 30%;
	    }
	}

	.answer {
	    &-label {
	    	background: #FF5D69;
	    }
    
	    &-button {
			background: #FF5D69;
	    }
	    
	    &-button:hover, &-button.selected {
			background: #525252;
			color: #fff;
			.answer-label {
				background: #525252;
				color: #fff;
			}
    	}
	}

	#transition {
		z-index: 1;
		pointer-events: none;
		z-index: 2;
	}

	.number {
		position: absolute;
		top: 0;
		bottom: 0;
		margin: auto;
		left: 0;
		right: 0;
		opacity: 0;
	}

	.feedback {
	    position: absolute;
	    top: 21%;
		left: 15%;
	    height: 45%;
	    width: 70%;
		padding: 40px;
		border-radius: 12px;
		display: block;
		&-icon {
			float: left;
			height: 100%;
			margin-bottom: 0;
			margin-right: 40px;
		}
		&-container {
			height: 100%;
		}
		&-next-container {
			position: absolute;
			width: 100%;
			top: 68%;
			text-align: center;
		}
	}

	.next-button {
	    background: #FFF;
	    font-family: 'OpenSans';
	    padding: 10px 25px;
	    border-radius: 20px;
	    font-size: 24px;
	    line-height: 24px;
	    &:hover, &:focus {
			background: #FF5C67;
			color: #fff
	    }
	}

	#skeleton-hand, #skeleton-torso, #skeleton-torso2, #skeleton-knee, #skeleton-knee2 {
		position: absolute;
		top: 0;
	}

	#skeleton-hand {
		left: 0;
		width: 390px;
		height: 634px;
		transform: translateX(1024px);
	}

	#skeleton-torso {
		right: 0;
		width: 600px;
		height: 680px;
	}

	#skeleton-torso2 {
		left: 0;
		width: 600px;
		height: 680px;
	}

	#skeleton-knee, #skeleton-knee2 {
		right: 0;
		width: 364px;
		height: 680px;
	}

	#skeleton-hand, #skeleton-torso {
		transform: translateX(1024px);
	}

	#skeleton-knee {
		transform: translateX(424px);
	}

	&.multiple-choice {
		.passage-container, .article > span, ul.answers-container {
			&::-webkit-scrollbar {
				&-thumb {
				  background-color: rgba(255, 46, 60, 1);
				}
				&-button {
					&:vertical:decrement { /* Up */
						background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" preserveAspectRatio="none"><path d="M45.66,43.5a3,3,0,0,1-2.6,4.5H4.94a3,3,0,0,1-2.6-4.5l19.06-33a3,3,0,0,1,5.2,0Z" fill="rgba(255, 46, 60, 1)" /></svg>');
					}
					&:vertical:increment { /* Down */
						background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" preserveAspectRatio="none"><path d="M45.66,4.5A3,3,0,0,0,43.07,0H4.94a3,3,0,0,0-2.6,4.5l19.06,33a3,3,0,0,0,5.2,0Z" fill="rgba(255, 46, 60, 1)" /></svg>');
					}
				}
			}
		}
		.question-text {
			&::-webkit-scrollbar {
				&-track {
    				background-color: rgba(255, 255, 255, 0.75);
				}
				&-thumb {
				  background-color: rgba(255, 46, 60, 1);
				}
				&-button {
					background-color: rgba(255, 255, 255, 0.75);
					&:vertical:decrement { /* Up */
						background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" preserveAspectRatio="none"><path d="M45.66,43.5a3,3,0,0,1-2.6,4.5H4.94a3,3,0,0,1-2.6-4.5l19.06-33a3,3,0,0,1,5.2,0Z" fill="rgba(255, 46, 60, 1)" /></svg>');
					}
					&:vertical:increment { /* Down */
						background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" preserveAspectRatio="none"><path d="M45.66,4.5A3,3,0,0,0,43.07,0H4.94a3,3,0,0,0-2.6,4.5l19.06,33a3,3,0,0,0,5.2,0Z" fill="rgba(255, 46, 60, 1)" /></svg>');
					}
				}
			}
		}
	}

	&::-webkit-scrollbar {
		&-thumb {
		  background-color: rgba(255, 46, 60, 1);
		}
		&-button {
			&:vertical:decrement { /* Up */
				background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" preserveAspectRatio="none"><path d="M45.66,43.5a3,3,0,0,1-2.6,4.5H4.94a3,3,0,0,1-2.6-4.5l19.06-33a3,3,0,0,1,5.2,0Z" fill="rgba(255, 46, 60, 1)" /></svg>');
			}
			&:vertical:increment { /* Down */
				background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" preserveAspectRatio="none"><path d="M45.66,4.5A3,3,0,0,0,43.07,0H4.94a3,3,0,0,0-2.6,4.5l19.06,33a3,3,0,0,0,5.2,0Z" fill="rgba(255, 46, 60, 1)" /></svg>');
			}
		}
	}

}