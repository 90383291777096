$width_limbs: 218px;
$height_limbs: 218px;
$width_body: 113px;
$height_body: 133px;

.helperWrapper {
    text-align: center;

    .helper {
        display: grid;
        grid-template-columns: 1fr;

        height: 100%;
    
        .body, .limbs {
            grid-row-start: 1;
            grid-column-start: 1;
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
        .body {
            width: $width_body;
            height: $height_body;
            margin-top: 15px;
        }
        .limbs {
            width: $width_limbs;
            height: $height_limbs;
        }
    
    }
}