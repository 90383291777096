.syn-ant {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-family: 'OpenSans', sans-serif;
  color: #000;

  .container {
    width: 100%;
    max-width: 1024px;
    height: 100%;
    max-height: 680px;
    position: relative;
    overflow: hidden;
    background-color: #2D2D2D;
  }

  .back-button {
    width: 70px;
    height: 70px;
    position: absolute;
    z-index: 10;
    left: 10px;
    top: 10px;
  }

  .book {
    width: 70px;
    height: 70px;
    position: absolute;
    z-index: 10;
    right: 10px;
    top: 10px;
    background-repeat: no-repeat;
  }

  .focused {
    outline: 3px solid transparent !important;
    box-shadow: 0 0 0 3px #1916ff, 0 0 0 5px #ffffff !important;
  }

  .next-button {
    font-family: 'Open Sans', sans-serif;
    color: #000;
    padding: 10px 25px;
    border-radius: 20px;
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 0;
    margin-top: 16px;
    opacity: 0;
  }

  .feedback-next-container .next-button {
    opacity: 0;
  }

  .center {
    text-align: center;
  }

  .book[disabled] {
    opacity: 0.4;
  }

  .book[disabled]:hover {
    cursor: not-allowed;
  }

  .next-button[disabled]:hover {
    color: #000;
    background: #9e9e9e;
    cursor: not-allowed;
  }
  .next-button[disabled] {
    background: #9e9e9e;
  }
}
