#app_main .wrapper {
    height: 100%;
}

.dev-wrapper {
    position: absolute;
    z-index: 1000;
    
    .devci-button {
      color:red;
      background-color: white;
      margin: 10px;
    }
  }

  