@import "src/app/styles/variables.module";

.home-page-content {
  margin-top: 55px;
  width: 100%;
  height: calc(100% - 55px);
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &.gray-outed {
    opacity: 0.5;
  }

  .home-page-items {
    height: calc(100% - 80px);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    transition: transform 0.7s ease-in-out;

    .stats-block {
      background-color: #eae2dd;
      display: flex;

      .helper-box {
        flex: 1;
      }

      .stats-box {
        flex: 1;
      }

      &.active {
        transform: translateX(-100%)
      }
    }
  }

  .slider-item {
    width: 100%;
    flex-shrink: 0;
  }

  .play-block {
    background-color: #eae2dd;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: row;

    .back-button {
      position: absolute;
      top: 5px;
      left: 5px;
      width: 70px;
      height: 70px;
      background-repeat: no-repeat;
    }

    .helper-box {
      flex: 1;
    }

    .activities-box {
      flex: 1;
    }

    &.active {
      transform: translateX(0)
    }
  }

  .header {
    display: flex;

    .emptySection {
      flex-grow: 1;
    }

    .subnav {
      flex-grow: 2;
    }
  }

  .footer {
    display: flex;
  }

  .tabs {
    height: calc(100% - 80px);
  }

  .next-button-box {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
  }

  .next-button {
    margin-bottom: 100px;
    cursor: pointer;
    transform: matrix(1, 0, 0, 1, 0, 0);
    padding: 0 10px;
  }
}
