$header-height: 80px;
$footer-height: 80px;

$theme-c-cello: #213F5A;
$theme-reporting-color: #14405c;
$theme-teacher-background: #eae2dd;

@font-face {
    font-family: "BentonSansBook";
    src: url("../shared/fonts/bentonSans-Book.woff") format("woff"), url("../shared/fonts/bentonSans-Book.ttf") format("truetype");
}
