@import "src/app/styles/variables.module";

.reporting-page-content {
  margin-top: 55px;
  width: 100%;
  height: calc(100% - 55px);
  display: flex;
  flex-direction: column;

  background-color: $theme-teacher-background;

  .reporting {
    flex: 1;
    height: calc(100% - 80px);
  }

  .tabs {
    height: calc(100% - 80px);
  }

  .tabs_panels, .tabs_panel {
      height: 100%;
  }

  .header {
    display: flex;

    .emptySection {
      flex-grow: 1;
    }

    .subnav {
      flex-grow: 2;
    }
  }

  .footer {
    display: flex;

    .emptySection {
      flex-grow: 100;
    }

    .internet-icon {
      flex-grow: 1;
    }
  }
}
