.activity {
  margin-top: 55px;
  width: 100%;
  height: calc(100% - 55px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  color: #14405c;

  a {
    margin: 20px;
    border: 1px solid black;
    background-color: #14405c;
    width: 200px;
    height: 30px;
  }

  b, strong {
    font-family: 'OpenSans';
  }
}
