@import "src/app/styles/variables.module";

.tab-content {

    display: inline-block;
    max-height: calc(100% - 59px - 10px);

    h3 {
        margin: 0 10px 10px 10px;
        font-family: OpenSans;
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $theme-reporting-color;
      
    }

    
    .tab-pane {
        margin-top: 10px;
        width: 100%;
        height: 75%;
    
        .activityList {
            column-count: 4;
            column-fill: auto;
            column-rule: 2px solid $theme-reporting-color;
    
            width: 100%;
            height: 100%;
            
            margin-bottom: 5px;
        
            li {
                &.label {
                    margin: 12px 4px 12px 5px;
                    font-family: OpenSans;
                    font-size: 20px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: $theme-reporting-color;

                    &:first-child {
                        margin-top: 0px;
                        padding-top: 0px;
                    }
                }

                &.listOption {
                    min-height: 20px;
                    margin: 7px 10px 5px 20px;
                    font-family: OpenSans;
                    font-size: 16px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: $theme-reporting-color;
                    
                }
            }        
        }
    }
}
