.activity-list-box {
  display: flex;
  flex-direction: row;
  height: 100%;
  display: flex;
  justify-content: center;

  .slide-in-top {
    -webkit-animation: slide-in-top 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-top 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }

  .text-focus-in {
    animation: text-focus-in 1.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  }

  .roll-out-left {
    -webkit-animation: roll-out-left 1s ease-in both;
    animation: roll-out-left 1s ease-in both;
  }

  .vertical-line {
    width: 20px;
    height: 100%;
    background-color: #fff;
  }

  .slide-left {
    -webkit-animation: slide-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }

  .slide-right {
    -webkit-animation: slide-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }

  .activity-list {
    width: 100%;
    max-width: 200px;
    background: #fff;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    .activity-item {
      list-style-type: none;
      font-size: 25px;
      padding-bottom: 10px;
      color: #14405b;
    }
  }
}
