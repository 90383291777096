.zombie {
  background-color: #000;

  #ooze {
    z-index: 3;
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .container {
    opacity: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 25px;
    background: url("images/zombie_background2.svg");
    background-size: 100% 100%;
  }

  .title-screen, #zombie_title {
    position: absolute;
    z-index: 3;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    pointer-events: none;
  }

  .activity-card {
    position: absolute;
  }

  .zombie-book-bg, .zombie-book-fg, #zombie-dripping, #zombie-hungry, #house-bg {
    position: absolute;
  }

  .zombie-book-bg {
    bottom: 0;
    left: 0;
    width: 1001px;
    height: 657px;
  }

  .zombie-book-fg {
    left: 28px;
    bottom: 40px;
    z-index: 2;
    pointer-events: none;
  }

  #house-bg {
    top: 0;
    left: 0;
    width: 1024px;
    height: 680px;
  }

  #zombie-dripping, #zombie-hungry {
    top: 28%;
    left: 19%;
  }

  #dripping-zombie, #hungry-zombie {
    overflow: visible;
  }

  #doo-dads {
    position: absolute;
    margin-left: -3%;
    #dripping-zombie, #hungry-zombie {
      width: 92px;
      height: 146px;
    }
    #doodad-dripping, #doodad-hungry {
      display: inline-block;
    }
    #doodad-dripping {
      transform: translateY(18%);
    }
  }

  .title-zombie-book {
    position: absolute;
    width: 607px;
    height: 662px;
    left: 15%;
    bottom: 0;
  }

  &-right {
    order: 2;
  }

  .choices {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 44px 48px 60px 155px;

    .article {
      max-width: 350px;
      color: black;
      height: 450px;
      float: left;
      margin-top: 120px;
    }

    .answers {
      width: 51%;
      max-width: 430px;
      height: 100%;
      float: right;

      .prompt {
        padding: 12px 20px;
        color: black;
        font-family: OpenSans, sans-serif;
        font-size: 22px;
        background-color: #90edea;
      }

      .answers-container {
        padding: 20px;
        max-width: 430px;

        .answer-button {
          margin: 10px;

        }
      }
    }
  }

  .answer, .answer-item  {
    &-label {
      background: #c8c8c8;
    }
    
    &-button {
      background: #c8c8c8;
    }
    
    &-button:hover, &-button.selected {
      background: #31562d;
      color: #fff;
      .answer-label {
        background: #31562d;
        color: #fff;
      }
    }
  }

  .feedback-next-container {
    width: 350px;
    position: absolute;
    text-align: center;
    margin-top: 500px;
  }

  #feedback-correct, #feedback-incorrect {
    height: 480px;
  }

  .feedback {
    max-width: 350px;
    padding: 20px;
    position: absolute;
    justify-content: center;

    .feedback-icon, .feedback-title, .feedback-text {
      padding-top: 10px;
    }
  }

  .passage, .question {
    color: black;
    font-size: 22px;
    line-height: 33px;
    width: 34%;
    left: 58%;
    top: 10%;
    height: 530px;
    border-radius: 10px 10px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-title {
      font-size: 24px;
      line-height: 36px;
      padding-bottom: 30px;
      font-family: 'OpenSans Bold';
    }

    .center {
      margin-top: 20px;
      height: 15%;
    }
  }

  .passage-text {
    text-align: left;
    display: block;
    overflow-y: auto;
  }

  .passage-container {
    height: auto;
  }

  .question-text {
    padding: 40px;
    background-color: #90edea;
    overflow-y: auto;
    height: auto;
  }

  .next-button {
    background: #C8C8C8;
    margin-top: 0;
    margin-bottom: 0;
    &:hover, &:focus {
      background: #31562d;
      color: #fff;
    }
  }

  .feedback-card {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 60px 0 0 140px;
  }

  #thumbs-up, #thumbs-down {
    position: absolute;
    right: 0;
    bottom: 0;
    opacity: 0;
    z-index: 1;
  }

  &.multiple-choice {
    .passage-container, .question-text, .article > span, ul.answers-container {
      &::-webkit-scrollbar {
        &-thumb {
          background-color: rgba(88, 141, 141, 1);
        }
        &-button {
          &:vertical:decrement { /* Up */
            background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" preserveAspectRatio="none"><path d="M45.66,43.5a3,3,0,0,1-2.6,4.5H4.94a3,3,0,0,1-2.6-4.5l19.06-33a3,3,0,0,1,5.2,0Z" fill="rgba(88, 141, 141, 1)" /></svg>');
          }
          &:vertical:increment { /* Down */
            background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" preserveAspectRatio="none"><path d="M45.66,4.5A3,3,0,0,0,43.07,0H4.94a3,3,0,0,0-2.6,4.5l19.06,33a3,3,0,0,0,5.2,0Z" fill="rgba(88, 141, 141, 1)" /></svg>');
          }
        }
      }
    }
  }

  &::-webkit-scrollbar {
    &-thumb {
      background-color: rgba(88, 141, 141, 1);
    }
    &-button {
      &:vertical:decrement { /* Up */
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" preserveAspectRatio="none"><path d="M45.66,43.5a3,3,0,0,1-2.6,4.5H4.94a3,3,0,0,1-2.6-4.5l19.06-33a3,3,0,0,1,5.2,0Z" fill="rgba(88, 141, 141, 1)" /></svg>');
      }
      &:vertical:increment { /* Down */
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" preserveAspectRatio="none"><path d="M45.66,4.5A3,3,0,0,0,43.07,0H4.94a3,3,0,0,0-2.6,4.5l19.06,33a3,3,0,0,0,5.2,0Z" fill="rgba(88, 141, 141, 1)" /></svg>');
      }
    }
  }

}
