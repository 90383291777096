@import "src/app/styles/variables.module";

.wordsViewDropdown {
    margin-bottom: 10px;
    width: 25%;
    
    #selectDropdown {
        border-bottom: 2px solid black;

        .selectName {
            font-weight: 900;
        }
    }
}

.wordsFilter {
    display: inline-block;
    width: 100%;

    .searchWordsWrapper {
        display: inline-block;
        height: 100%;
        width: 20%;
        min-width: 100px;
    }
    
    .filterRow {
        padding-bottom: 20px;
        display: inline-block;
        width: 70%;

        .wordsGradeDropdown, .wordsStreamDropdown, .wordsUnitDropdown, .wordsSubUnitDropdown {
            display: inline-block;
            margin-left: 20px;
            margin-top: 10px;
            height: 100%;
            
            width: 45%;

            min-width: 150px;

            .selectDropdownButton {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
        
                background-color: white;
        
                border: 3px solid $theme-reporting-color;
                text-align:left;
                padding-left: 10px;
                vertical-align: middle;
            }
        }
    }
}