@import "src/app/styles/variables.module";

$list-item-border: 5px;

$scroll-width: 2ch;
$thumb-border-width: 6px;

.reporting {
    overflow-y: auto;

    .closeButton {
        position: absolute;
        width: 35px;
        height: 35px;
        top: 5px;
        right: 15px;
        overflow: visible;
        background: url('../../shared/images/teacher/x-button.svg');
    }

    .reportingPage {
        background-color: $theme-teacher-background;
        .loadingActivity {
            background-color: $theme-teacher-background;
        }

        height: 100%;
        padding-top: 2%;

        .centeredPage {
            display: flex;
            flex-direction: column;
            flex-flow: row wrap;
            flex-direction: column;
            flex-wrap: nowrap;
            flex-grow: 1;

            justify-content: flex-start;

            align-self: center;

            margin: auto;

            max-height: 100%;
            height: 100%;

            max-width: 90%;
            width: 90%;

            overflow: auto;

            .center-vertical {
                position: relative;
                top: 50%;
                -webkit-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                transform: translateY(-50%);
            }

            .title {
                font-family: "OpenSans";
                font-size: 24px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: $theme-reporting-color;
            }

            .text {
                font-family: "OpenSans";
                font-size: 20px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: $theme-reporting-color;

                &.red {
                    color: red;
                }
                &.yellow {
                    color: darkgoldenrod;
                }
            }

            // @TODO: Move out into possibly separate css
            // The table styling
            .table {
                height: 100%;

                table {
                    border-collapse: collapse;

                    width: 100%;
                    border-spacing: 0;

                    // needs to be to be block so we can enforce height and get scroll
                    thead, tbody, tr, th, td { display: block; }

                    thead {
                        tr {
                            

                            /* minus scroll bar width when scrollbar active */
                            width: -webkit-calc(100% - $scroll-width);
                            width:    -moz-calc(100% - $scroll-width);
                            width:         calc(100% - $scroll-width);
                        }
                    }
                    
                    tr:after {
                        content: ' ';
                        display: block;
                        visibility: hidden;
                        clear: both;
                    }
                    
                    
                    tbody td, thead th {
                        // @TODO: Anywhere you want to make a custom table in reporting, change the width to
                        // width: calc(100% / #columns)
                        float: left;
                    }

                    tbody {
                        height: 40vh;
                        overflow-y: scroll;
                        overflow-x: hidden;
                        border: $list-item-border solid $theme-reporting-color;

                        tr.emptyClassTr {
                            width: 100%;
                            height: 100%;

                            &:hover {
                                background-color: transparent;
                            }

                            &.hasDemo {
                                margin-top: -50px;
                                td {
                                    margin-top: -50px;
                                }
                            }
        
                            .emptyClassTd {
                                height: 100%;
                                width: 100%;
                                cursor:default;

                                center {
                                    height: 100%;
                                    .emptyClassCard {
                                        height: 75%;
                                        width: 75%;
                                        background-color: #dddddd;
                                        border: 3px solid $theme-reporting-color;
                                        white-space: normal;
        
                                        a {
                                            pointer-events:all;
                                            color: purple;
                                        }
        
                                        h1, p {
                                            margin: 1vh .5vw 1vh .5vw;
                                            overflow-wrap: break-word;
                                        }
                                    }
                                }
                            }
                        }

                        &::-webkit-scrollbar {
                            width: $scroll-width;
                        }
                        
                        &::-webkit-scrollbar-track {
                            background-color: $theme-reporting-color;
                            border-radius: 0px;
                        }
                        
                        &::-webkit-scrollbar-thumb {
                            border-radius: 0px;
                            border: $thumb-border-width solid $theme-reporting-color;
                            background-color: white;
                            background-clip: content-box;
                        }

                        &::-webkit-scrollbar-thumb:hover {
                            background-color: rgb(234, 233, 233);
                        }

                        tr {
                            td {
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                .text {
                                    width: 100%;
                                    overflow-x: hidden;
                                    text-overflow: ellipsis;
                                }
                            }
                            &:hover{
                                cursor: pointer;
                                background-color: $theme-reporting-color;
                                .text {
                                    color: white;
                                }
                            }
                            &:focus{
                                border: none;
                                outline: 2px solid #333;
                                outline-offset: 2px;
                            }
                        }
                    }
                

                    .align-left {
                        text-align: left;
                    }

                    .align-center {
                        text-align: center;
                    }

                    .studentTableHeader {
                        .carrot {
                            &.down::before {
                                display: inline-block;
                                content: "\f\25be";
                            }
                            &.up::before {
                                transform: rotate(180deg) scaleX(-1);
                                display: inline-block;
                                content: "\f\25be";
                            }
                        }

                    }

                    .listItem {
                        border: $list-item-border solid $theme-reporting-color;
                        border-left: 0;
                        border-right: 0;
                        border-top: 0;
                        height: 50px;

                        line-height: calc(50px - 10px);

                        &:last-child {
                            border-bottom: 5px solid $theme-reporting-color;
                        }
                    }

                }
            }
        }


    }
}