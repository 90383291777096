@import "src/app/styles/variables.module";

.footer {
  bottom: 0;
  width: 100%;
  height: 80px;
  background-color: $theme-c-cello;

  .connection-block {
    padding: 0 10px;
  }
}
