@import "src/app/styles/variables.module";

$num_cols: 3;
$scroll-width: 2ch;

.reportingPage {
    .centeredPage {
        .wordsPage {
            #coreTable {
                height: 100%;

                tbody td,
                thead th {
                    // divided by num cols
                    width: calc(100% / $num_cols);
                    float: left;
                }

                th {
                    height: 100%;

                    &.wordRow {
                        width: calc((100% / $num_cols) * 2);
                    }

                    &.noGradeRow {
                        width: calc((100% / $num_cols) * 2);
                    }

                    &.gradeRow {
                        width: calc((100% / $num_cols) * 3);
                    }
                }

                tbody {
                    tr {
                        border: 2px solid $theme-reporting-color;

                        &:hover:not(.lessonRow) {
                            color: white;
                        }

                        &.lessonRow {
                            background-color: white;

                            td {
                                width: 100%;
                                border-right: 0;
                            }
                        }

                        td {
                            padding: 3px 0px 3px 3px;
                            border-right: 2px solid $theme-reporting-color;
                            height: 100%;

                            &:last-child {
                                border-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}