.helper-container {
    .messages {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-top: 15px;
        overflow-y: none;
        height: 100%;

        margin-left: auto;
        margin-right: auto;

        .message {
        -webkit-animation: slide-right 1s cubic-bezier(0, 0.460, 0.450, 0.940) both;
        animation: slide-right 1s cubic-bezier(0, 0.460, 0.450, 0.940) both;

        width: 500px;
        background: #fff;
        color: #14405b;
        font-weight: bold;
        border-radius: 3px;
        font-size: 1vh;
        padding: 15px;
        margin-bottom: 20px;
        white-space: normal;
        cursor: pointer;

        h1 {
            z-index: 1;
        }

        &:last-child {
            &:before {
                position: absolute;
                background-color: white;
                width: 50px;
                height: 50px;
                border-top: 0px solid white;
                border-bottom: 2px solid white;
                border-left: 0px solid white;
                border-right: 2px solid white;
                top: 100%;
                left: 45%;
                margin-left: -25px;
                content: ' ';
                transform: rotate(45deg);
                margin-top: -25px;
                z-index: -1;
            }
        }
        }
    }
}