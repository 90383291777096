@import "src/app/styles/variables.module";

.settingsModal {
    .background-opacity{
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, .5);
        opacity: 0;
        transition: opacity .2s ease;
        display: flex;
        opacity: 1;
        z-index: 9;
    }

    .settingsPopupContent {
        padding: 20px 20px 35px 35px;
        background-color: white;
        border: 5px solid $theme-reporting-color;
        color: black;
        font-size: 16px;
        overflow: scroll;
    }
    
    .settingsPopup {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    
    
        width: 80vw;
        max-width: 1200px;
        min-width: 500px;
    
        height: 80vh;
        max-height: 600px;
        min-height: 300px;
    
        box-sizing: border-box;
        border-radius: 20px;
        z-index: 10;
    
        ul {
            list-style: none;
        }
    
        .top-pane {
            height: 59px;
    
            .nav-tabs {
                height: 59px;
                border-top: 0;
                border-bottom: 3px solid $theme-reporting-color;
    
                li {
                    &>button {
                        height: 100%;
                        min-width: 150px;
                        padding: 0 10px;
        
                        font-family: OpenSans;
                        font-size: 19px;
                        font-weight:500;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        text-align: center;
        
                        line-height: 54px;
                        color: $theme-reporting-color;
                        text-align: center;
        
                        margin-left: 5px;
                        
                        float: left;
        
                        color: #555;
                        background-color: #fff;
                        border: 3px solid $theme-reporting-color;
                        border-bottom: 0;
        
                        border-top-left-radius: 7px;
                        border-top-right-radius: 7px;
                        cursor: pointer;
                        
                        &.active {
                            background-color: $theme-reporting-color;
        
                            color: white;
                            font-weight: bold;
                        }
                    }
                }
        
            }
        }
    
        
        .tab-content {
            display: block;
    
            width: 100%;
            height: 100%;
    
        }
    
    
        .closeButton {
            position: absolute;
            width: 35px;
            height: 35px;
            top: 12px;
            right: 25px;
            overflow: visible;
            background: url('../../shared/images/teacher/x-button.svg');
        }
    
        .confirmButton {
            position: absolute;
            top: 12px;
            right: 75px;
            width: 144px;
            height: 54px;
            background-color: $theme-reporting-color;
            border: 2px solid $theme-reporting-color;
            border-radius: 15px;
            color: white;
    
            line-height: 54px;
            font-size: 18px;
            text-align: center;
        }
    
        .container {
            display: block;
            position: relative;
            padding-left: 20px;
            padding-top: 1px;
            font-size: 16px;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
    
            width: 100%;
            height: 100%;
    
            input.styled {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
            }
    
            // Custom checkmark
            .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                height: 16px;
                width: 16px;
                border: 3px solid $theme-reporting-color;
                
            }
    
            // When checked
            input.styled:checked~.checkmark {
                background-color: $theme-reporting-color;
                
            }
    
            .checkmark:after {
                display: none;
            }
              
        }
    
    }
}