.rightSection {

  button {
    color: white;
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 40px;
    font-family: OpenSans;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
  }
}
